import React from "react";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrizeDrawList } from "../../Redux/Action/AdminPannel";
import ReactPaginate from "react-paginate";
const PrizeDraw = ({ win }) => {
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const [filterName, setFilterName] = useState("All Subscribers");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [array, setArray] = useState();
  console.log(array);
  const dispatch = useDispatch();
  const prizeList = useSelector((state) => state?.AdminReducer?.prize_list);
  console.log(prizeList,"listDraw");
  useEffect(() => {
    dispatch(getPrizeDrawList());
  }, []);
  useEffect(() => {
    if (prizeList?.length > 0) {
      setArray(prizeList);
    }
  }, [prizeList]);

  const allSubFilter = () => {
    if (prizeList?.length > 0) {
      setArray(prizeList);
    }
  };
  const maleFilter = () => {
    const newArr = array.filter((prize) => prize?.user_id?.gender === 1);
    setArray(newArr);
  };
  const femaleFilter = () => {
    const newArr2 = array.filter((prize) => prize?.user_id?.gender === 2);
    setArray(newArr2);
  };
  const [subscribers, setSubscribers] = useState("");
  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % array.length);
  };
  useEffect(() => {
    if (page && array) {
      setPageCount(Math.ceil(array.length / page));
    }
  }, [array, page]);


const listuser=(pricedraw_data)=>{
var totaluser=pricedraw_data?.pricedraws?.user_detail

var data_append=[]
  for(var i=0;i<totaluser?.length;i++){
  
   
    data_append.push(<div>
<span>{totaluser[i]?.gamer_name} </span>
{/* <span>{totaluser[i]?.gamer_name} </span> */}
    </div>)

  }
  return  data_append

}


console.log(subscribers,"subscriberssubscribers")
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Price Draws</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              {/* <!-- <div className=" d-flex justify-content-end me-4">
                    <button className="" data-bs-toggle="modal" data-bs-target="#exampleModal" id="btn-add-contact">
                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </button>
                </div> --> */}
              <div className="btn-group  mb-2 " role="group ml-2">
                <button
                  id="btndefault"
                  type="button"
                  className="btn btn-outline-dark dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filterName}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </button>
                <div className="dropdown-menu" aria-labelledby="btndefault">
                  <a
                    onClick={() => {
                      setFilterName("All Subscribers");
                      allSubFilter();
                    }}
                    className="dropdown-item"
                  >
                    All Subscribers
                  </a>
                  <a
                    onClick={() => {
                      setFilterName("Male");
                      maleFilter();
                    }}
                    className="dropdown-item"
                  >
                    Male
                  </a>
                  <a
                    onClick={() => {
                      setFilterName("Female");
                      femaleFilter();
                    }}
                    className="dropdown-item"
                  >
                    Female
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll_prize-draw">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Images</th>
                      <th>Title</th>
                      <th>Link</th>
                      <th>Subscribe</th>
                      <th>Winner Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {array
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((data) =>
                        data?.title.toLowerCase().includes(search)
                      )
                      ?.map((item, index) => {
                     
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + index}</td>
                            <td>
                              <div className="d-flex">
                                <div className="usr-img-frame me-2 rounded-circle">
                                  <img
                                    alt="avatar"
                                    className="img-fluid"
                                    src={
                                      IMG_URL +
                                      "questimage/" +
                                      item?.image
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>{item?.title}</td>
                            <td>
                              <a href={item?.link}  target="_blank">Link</a>
                            </td>
                            <td>
                             
                            {item?.subscribes?.length} Subscribers
                            
                            </td>

                            <td>
                            
                              <div
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                                className="subscriber"
                                onClick={() =>
                                  setSubscribers(item?.pricedraws?.user_detail)
                                }
                              >
                               
                                Winner List

                              </div>
                            

                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
          activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>


      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Winer list
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"

              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="searchable-container searchable-items">
                      {subscribers?.length > 0 ? (
                        subscribers
                     
                          ?.map((item, i) => {
                            console.log(item,"")
                            console.log(item,"itemitem")
                            return (
                              <div className="user-profile d-flex subscriber-img mt-4">
                                <img
                                  src={
                                    IMG_URL +
                                    "user_profile_image/" +
                                    item.main_image
                                  }
                                  alt="avatar"
                                  className="rounded"
                                />
                                <div className="user-meta-info ms-2">
                                  <p className="user-name mb-0">
                                    {item.gamer_name}
                                  </p>
                                  <p className="user-email">{item?.email}</p>
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <div>No subscribers</div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
   
          </div>
        </div>
   
      </div>

    </Master>
  );
};

export default PrizeDraw;
