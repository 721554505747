import React from "react";
import Master from "../../Include/Master";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInterests,
  udpateUserDob,
  viewUserDetail,
} from "../../../Redux/Action/AdminPannel";
import { NavLink, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";

import DeleteModal from "../../DeleteModal/DeleteModal";
const UserDetail = () => {
  const [intData, setIntData] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [dob, setDOB] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const viewUser = useSelector((state) => state?.AdminReducer?.user_view);
  const intDataList = useSelector(
    (state) => state?.AdminReducer?.int_data_list
  );
  useEffect(() => {
    dispatch(viewUserDetail(id));
    dispatch(getInterests());
  }, []);

  useEffect(() => {
    const int = viewUser?.interest?.map((item) => item);
    // const keyword = int;
    const filtered = intDataList?.filter((entry) =>
      Object.values(entry).some(
        (val) => typeof val === "string" && val?.includes(int)
      )
    );
    setIntData(filtered);
  }, [viewUser, intDataList]);

  useEffect(() => {
    setDOB(viewUser?.date_of_birth?.split("T")[0]);
  }, [viewUser]);

  console.log(viewUser);

  const openLightbox = (url) => {
    setSelectedImage(url);
  };
  // console.log(selectedImage);

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  // const handleDobUpdate = () => {
  //   if (dob) {
  //     const obj = { user_id: id, dob };
  //     dispatch(udpateUserDob(obj));
  //   } else {
  //     toast.warning("Please provide D.O.B.");
  //   }
  // };

  console.log(selectedImage,"select")

  return (
    <Master>
      <NavLink to="/usermanagement">
        {" "}
        <img
          style={{ backgroundColor: "white" }}
          src={require("../../assets/img/backimage.png")}
          alt="avatar"
          // className="img-fluid mx-auto rounded-circle"
        />
      </NavLink>

      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="user-mgt.html">User Management</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                User Detail
              </li>
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        {/* <!-- <div className="row layout-top-spacing">
                        <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
                            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
                                <div className=" d-flex justify-content-end me-4">
                                    <button className="" data-bs-toggle="modal" data-bs-target="#exampleModal" id="btn-add-contact">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    </button>
                                </div>
                                <div className="btn-group  mb-2 " role="group ml-2">
                                    <button id="btndefault" type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                                    <div className="dropdown-menu" aria-labelledby="btndefault">
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-home-fill-1 mr-1"></i>Upcoming</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-gear-fill mr-1"></i>Present</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-bell-fill-2 mr-1"></i>Past</a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> --> */}

        <div className="row layout-top-spacing">
          <div className="col-md-8 mb-3 p-3">
            <div className="widget-content widget-content-area br-8">
              {viewUser && (
                <div className="detail_page_content">
                  <div className="course_info">
                    <div className="d-flex mb-2 align-items-center">
                      <div className="pe-3  main_profile_img">
                        {viewUser?.main_image ? (
                          <img
                            src={
                              IMG_URL +
                              "user_profile_image/" +
                              viewUser?.main_image
                            }
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        ) : (
                          <img
                            src={require("../../assets/def_user.png")}
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        )}
                      </div>
                      <div>
                        <div className="d-flex justify-content-center">
                          <h4>
                            {viewUser?.first_name} {viewUser?.sur_name},
                            <span>
                              {" "}
                              {parseInt(
                                new Date().getFullYear() -
                                  new Date(
                                    viewUser?.date_of_birth
                                  ).getFullYear()
                              )}
                            </span>
                            <div className="gamer-name">
                              {" "}
                              <label for="">Gamers Name :</label>{" "}
                              {viewUser?.gamer_name}
                            </div>
                          </h4>
                        </div>
                        <div className="d-flex justify-content-center1 country_details">
                          <p className="mb-0">
                            {/* <img
                              src={
                                IMG_URL +
                                "user_profile_image/" +
                                viewUser?.country_flag
                              }
                              className="flag-width img-fluid"
                              alt="flag"
                            /> */}
                            <span>{viewUser?.country}</span>
                          </p>
                          <p className="mb-0 ms-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="feather feather-user"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span className="">
                              {viewUser?.gender == 1
                                ? "Male"
                                : viewUser?.gender == 0
                                ? "Female"
                                : viewUser?.gender == 2
                                ? "Other"
                                : viewUser?.gender == 3 && "All Users"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="update-dob-div">
                        <h6 className="dob-heading">Change Age</h6>
                        <div>
                          <input
                            className="form-control dob-input"
                            type="date"
                            value={dob}
                            onChange={
                              (e) => setDOB(e.target.value)
                              // dispatch(
                              //   udpateUserDob({
                              //     dob: e.target.value,
                              //     user_id: id,
                              //   })
                              // )
                            }
                            // value={
                            //   viewUser?.date_of_birth
                            //     ? viewUser?.date_of_birth.split("T")[0]
                            //     : dob
                            // }
                          />
                          <div className="row layout-top-spacing">
                            <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
                              <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
                                <div className=" d-flex justify-content-end ">
                                  <button
                                    className=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    id="btn-add-contact"
                                    onClick={() => {
                                      // console.log(dob);
                                      dispatch(
                                        udpateUserDob({
                                          dob: dob,
                                          user_id: id,
                                        })
                                      );
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 about-section">
                    <h5>About me </h5>
                    <p>{viewUser?.about_me}</p>
                  </div>
                  <div className="mt-4 row">
                    <div className="col-md-6">
                      <div>
                        <h5>Game-Genre you like </h5>
                        <ul className="list-none p-0">
                          {viewUser?.game_geners.map((item, i) => {
                            return (
                              <li className="badge badge-light-dark mb-2 me-4">
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <h5>Interests</h5>

                        <ul className="list-none p-0">
                          {viewUser?.interest?.map((item, i) => {
                            const name = intDataList?.filter(
                              (data) => data?._id == item?.trim()
                            )[0];
                            return (
                              <li className="badge badge-light-dark mb-2 me-4">
                                {name?.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row pictures mt-4">
                    <div className="col-md-6">
                      <h5>Other Details</h5>
                      <div className="course_info row other-dtails">
                        <div className="col-md-6">
                          <div className="mb-3 d-flex">
                            <div>
                              <h5>Language </h5>
                              <p className="mb-0">{viewUser?.language}</p>
                            </div>
                          </div>
                          <div className=" d-flex mb-3">
                            <div>
                              <h5>Gamer experience</h5>
                              <p className="mb-0">
                                {viewUser?.gamer_experience}
                              </p>
                            </div>
                          </div>
                          <div className=" d-flex">
                            <div>
                              <h5>
                                Gender you're interested in - for Teammate
                              </h5>
                              <p className="mb-0">
                                {viewUser?.interested_in_for_teammate?.map(
                                  (item, i) =>
                                    item == "1"
                                      ? "Male "
                                      : item == "0"
                                      ? "Female "
                                      : item == "2"
                                      ? "Other "
                                      : item == "3" && "All Users"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3 d-flex">
                            <div>
                              <h5>Platforms</h5>
                              {viewUser?.platform.map((item, i) => {
                                return <p className="mb-0">{item}</p>;
                              })}
                            </div>
                          </div>
                          <div className="d-flex mb-3">
                            <div>
                              <h5>Player-Type</h5>
                              <p className="mb-0">{viewUser?.player_type}</p>
                            </div>
                          </div>
                          <div className=" d-flex">
                            <div>
                              <h5>
                                Gender you're interested in - for Soulmate
                              </h5>
                              <p className="mb-0">
                                {viewUser?.interested_in_for_soulmate?.map(
                                  (item, i) =>
                                    item == "1"
                                      ? "Male "
                                      : item == "0"
                                      ? "Female "
                                      : item == "2"
                                      ? "Other "
                                      : item == "3" && "All Users"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5>Pictures</h5>
                      <div className="extra_images">
                        {viewUser?.image.map((item, i) => {
                          return (
                            <a className="defaultGlightbox glightbox-content">
                              <img
                                src={IMG_URL + "user_profile_image/" + item}
                                alt=""
                              />
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4 images_card">
                    <div className="col-md-4  p-3">
                      <h5>Back view</h5>
                      <a className="defaultGlightbox glightbox-content">
                        {viewUser?.back_image ? (
                          <img
                            onClick={() => {
                              console.log("first");
                              openLightbox(
                                IMG_URL + "user_docs/" + viewUser?.back_image
                              );
                            }}
                            src={IMG_URL + "user_docs/" + viewUser?.back_image}
                            alt="image"
                            className="img-fluid"
                          />
                        ) : (
                          <img
                           onClick={() => {
                              console.log("first");
                              openLightbox(
                                require("../../assets/def_user.png")
                              );
                            }}
                            src={require("../../assets/def_user.png")}
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        )}
                      </a>
                    </div>
                    <div className="col-md-4  p-3">
                      <h5>Front view</h5>
                      <a className="defaultGlightbox glightbox-content">
                        {viewUser?.front_image ? (
                          <img
                           onClick={() => {
                              console.log("first");
                              openLightbox(
                                IMG_URL + "user_docs/" + viewUser?.front_image
                              );
                            }}
                            src={IMG_URL + "user_docs/" + viewUser?.front_image}
                            alt="image"
                            className="img-fluid"
                          />
                        ) : (
                          <img
                           onClick={() => {
                              console.log("first");
                              openLightbox(
                                require("../../assets/def_user.png")
                              );
                            }}
                            src={require("../../assets/def_user.png")}
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        )}
                      </a>
                    </div>
                    <div className="col-md-4  p-3">
                      <h5>Selfie</h5>
                      <a className="defaultGlightbox glightbox-content">
                        {viewUser?.selfie ? (
                          <img
                           onClick={() => {
                              console.log("first");
                              openLightbox(
                                IMG_URL + "user_docs/" + viewUser?.selfie
                              );
                            }}
                            src={IMG_URL + "user_docs/" + viewUser?.selfie}
                            alt="image"
                            className="img-fluid mx-auto bdr_radius"
                          />
                        ) : (
                          <img
                           onClick={() => {
                              console.log("first");
                              openLightbox(
                                require("../../assets/def_user.png")
                              );
                            }}
                            src={require("../../assets/def_user.png")}
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        )}
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="delete-account">
                        <DeleteModal
                          show={deleteShow}
                          data={viewUser?._id}
                          setShowVal={setDeleteShow}
                          modalName={"profile"}
                        />
                        <NavLink
                          onClick={() => {
                            setDeleteShow(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>{" "}
                          Delete Account
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
       
      </div>

      {selectedImage !== null && (
        <div className="lightbox">
          <span className="close" onClick={closeLightbox}>
            &times;
          </span>
          <img
            src={selectedImage}
            alt={`Image ${selectedImage}`}
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
            }}
          />
        </div>
      )}

      
    </Master>
  );
};

export default UserDetail;
