import React from 'react'
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
const AgeCountChart=({ageData,ageGraph})=> {

  console.log(ageData,ageGraph,"agegraph")
    const series= [{
        name: 'Users',
        data:ageGraph
      }]
       
     const options= {
        
        dataLabels: {
          enabled: false
        },
        
        grid: {
            show: false,
          },
        //   legend: {
        //     show: false,
        //   },
        
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ageData
        //   tickPlacement: 'off'
        },
        yaxis: {
          title: {
            text: 'Users',
          },
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     shade: 'light',
        //     type: "horizontal",
        //     shadeIntensity: 0.25,
        //     gradientToColors: undefined,
        //     inverseColors: true,
        //     opacityFrom: 0.85,
        //     opacityTo: 0.85,
        //     stops: [50, 0, 100]
        //   },
        // }
      }
    
    
      return (
        <>
          <ReactApexChart options={options}  series={series}  type="bar" height={450} />
        </>
      );
}

export default AgeCountChart;
