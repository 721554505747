import React from 'react'

const EmptyBox = () => {
    return (
        <div className="chat-box">
            <div className="chat_box_content">
                <div className="chat_concersation_head d-flex justify-content-between">
                    <div className="current-chat-user">
                        <span className="d-flex align-items-center">
                            
                            
                            {/* <!-- <span className="name"></span> --> */}
                        </span>
                    </div>

                    <div className="current-chat-user">
                        <span className="d-flex align-items-center">
                           
                            {/* <!-- <span className="name"></span> --> */}
                        </span>
                    </div>
                </div>
            </div>
            <div className="chat-conversation-box-main chatbox_empty">
                <div className="chat-conversation-box-scroll">
                    <div className="chat">
                        <div className="conversation-start">
                            <span className='no_text_chat'>No Chats Yet</span>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyBox