import React from "react";
import { useDispatch } from "react-redux";
import Master from "../../Include/Master";
import RTextAditor from "./RTextAditor";
import { useFormik } from "formik";
import { useState } from "react";
import { addBlogData, getBlogList } from "../../../Redux/Action/AdminPannel";
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
const CreateBlogs = () => {
  const [img, setImg] = useState();
  const [htmlContent, setHtmlContent] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataHtmlHandle = (html) => {
    // console.log(
    //     draftToHtml(JSON.parse( html)))

    setHtmlContent(draftToHtml(JSON.parse(html)));
  };

  const validate = (values) => {
    const errors = {};
    if (!values.post_title) {
      errors.post_title = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      post_title: "",
      content: "",
      blog: "",
    },
    validate,
    onSubmit: async (values, e) => {
      let formData = new FormData();
      formData.append("blog", img);
      formData.append("post_title", values.post_title);
      formData.append("content", htmlContent);
      try {
        await dispatch(addBlogData(formData)).then(navigate("/blogs"));
        dispatch(getBlogList());
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="blogs.html">Blogs</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create New Blog
              </li>
            </ol>
          </nav>
        </div>

        <form
          className="row mb-4 layout-spacing layout-top-spacing"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="widget-content widget-content-area blog-create-section">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.post_title}
                    name="post_title"
                    type="text"
                    className="form-control"
                    id="post-title"
                    placeholder="Title"
                  />
                  {formik.errors.post_title ? (
                    <p style={{ color: "red" }}>{formik.errors.post_title}*</p>
                  ) : null}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-sm-12">
                  <label>Content</label>
                  <div id="blog-description">
                    <RTextAditor htmltext={dataHtmlHandle} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12  mt-4">
            <div className="widget-content widget-content-area blog-create-section">
              <div className="row">
                <div className="col-xxl-12 col-md-12 mb-4">
                  <label for="product-images">Featured Image</label>
                  <div className="multiple-file-upload">
                    {/* <input
                      onChange={(e) => setImg(e.target.files[0])}
                      name="filepond"
                      type="file"
                      className="filepond file-upload-multiple"
                      id="product-images"
                      multiple
                      data-allow-reorder="true"
                      data-max-file-size="3MB"
                      data-max-files="5"
                    /> */}
                    <input
                      onChange={(e) => setImg(e.target.files[0])}
                      name="file"
                      type="file"
                      id=""
                      class="form-control"
                      placeholder="Upload Photo"
                    ></input>
                  </div>
                </div>

                <div className=" col-sm-4 col-12 mx-auto">
                  <button type="submit" className="btn drk_btn w-100">
                    Add Blog
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* <!-- Modal --> */}
    </Master>
  );
};

export default CreateBlogs;
