import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { LoginAdmin } from "../../Redux/Action/LoginAction";
import Cookies from "universal-cookie";

export default function Login() {
  var cookies = new Cookies();
  useEffect(() => {
    const token = cookies.get("adminToken");
    if (token) {
      sessionStorage.setItem("accessToken", token);
      window.location.href = "/admin";
    }
  }, []);

  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      console.log(values,"dfffffffffffff")
      dispatch(LoginAdmin(values));
    },
  });

  return ( 
      <div className="auth-container d-flex">
        <div className="container mx-auto align-self-center">
          <div className="row">
            <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
              <div className="auth-cover-bg-image"></div>
              <div className="auth-overlay"></div>

              <div className="auth-covers">
                <div className="position-relative">
                  <img
                    src={require("../assets/img/login-logo.png")}
                    alt="auth-img"
                    className="img-fluid"
                  />

                  <h2 className="mt-5 text-white font-weight-bolder px-2">
                    Lorem ipsum dolor sit amet{" "}
                  </h2>
                  <p className="text-white px-2">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h2>Sign In</h2>
                        <p>Enter your email and password to login</p>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />

                          {formik.errors.email ? (
                            <p style={{ color: "red  !important" }}>
                              {formik.errors.email}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-4">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="inputPassword3"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="password"
                            value={formik.values.password}
                          />
                          {formik.errors.password ? (
                            <p style={{ color: "red  !important" }}>
                              {formik.errors.password}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <div className="form-check form-check-primary form-check-inline">
                            <input
                              onChange={(e) => setRememberMe(e.target.checked)}
                              className="form-check-input me-3"
                              type="checkbox"
                              id="form-check-default"
                            />
                            <label
                              className="form-check-label"
                              for="form-check-default"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-4">
                          <a>
                            <button
                              type="submit"
                              className="btn btn-secondary w-100"
                            >
                              SIGN IN
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="text-center">
                          {/* <p className="mb-0">
                            <a
                              href="forget-password.html"
                              className="text-warning"
                            >
                              Forget password?
                            </a>
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}
