import React from "react";

const ChatBox = ({ userConvo, convoUsers }) => {
  console.log(convoUsers,"convoUsers");
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  return (
    <div className="chat-box">
      <div className="chat_box_content">
        <div className="chat_concersation_head d-flex justify-content-between">
          <div className="current-chat-user">
            <span className="d-flex align-items-center">
              <img
                src={
                  IMG_URL +
                  "user_profile_image/" +
                  convoUsers.firstUser.main_image
                }
                alt="dynamic"
                className="rounded"
              />
              <h6 className="ms-2">
                {convoUsers?.firstUser?.gamer_name}
              </h6>
              {/* <!-- <span className="name"></span> --> */}
            </span>
          </div>

          <div className="current-chat-user">
            <span className="d-flex align-items-center">
              <img
                src={
                  IMG_URL +
                  "user_profile_image/" +
                  convoUsers.secondUser.main_image
                }
                alt="dynamic"
                className="rounded"
              />
              <h6 className="ms-2">
                {convoUsers?.secondUser?.gamer_name}
              </h6>
              {/* <!-- <span className="name"></span> --> */}
            </span>
          </div>
        </div>
      </div>
      <div className="chat-conversation-box-main">
        <div className="chat-conversation-box-scroll">
          <div className="chat">
            <div className="conversation-start">
              <span>Today, 6:48 AM</span>
            </div>
            {userConvo?.map((msg) => {
              return msg.sender_id == convoUsers.firstUser._id ? (
                <div className="bubble you">{msg.message}</div>
              ) : (
                <div className="bubble me">{msg.message}</div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
