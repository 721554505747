import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Master from "../Include/Master";
import { Userlist } from "../../Redux/Action/LoginAction";
import { avgtimeuser, getDataQuest } from "../../Redux/Action/AdminPannel";
import moment from "moment";
import ProgressBar from "react-bootstrap/ProgressBar";
import PieCharts from "./PieCharts";
import QuiestPieCharts from "./QuestPieChart";
export const Dashboard = () => {
  const customDateRangeInitVals = {
    totalUsersFrom: "",
    totalUsersTo: "",
    activeUsersFrom: "",
    activeUsersTo: "",
    inActiveUsersFrom: "",
    inActiveUsersTo: "",
    newRegUsersFrom: "",
    newRegUsersTo: "",
    genderRatioFrom: "",
    genderRatioTo: "",
    userRatioFrom: "",
    userRatioTo: "",
    questRatioFrom: "",
    questRatioTo: "",
  };

  const percentInitVals = {
    totalUserswow: "",
    totalUsersmom: "",
    totalUsersyoy: "",
    activeUserswow: "",
    activeUsersmom: "",
    activeUsersyoy: "",
    inactiveUserswow: "",
    inactiveUsersmom: "",
    inactiveUsersyoy: "",
  };
  const genderInitVals = {
    male: [],
    female: [],
    others: [],
  };
  const userRatioInitVals = {
    verified: [],
    unVerified: [],
  };
  const questRatioInitVals = {
    sub: [],
    nonSub: [],
  };
  const dispatch = useDispatch();
  const [weekRange, setWeekRange] = useState();
  const [monthRange, setMonthRange] = useState();
  const [yearRange, setYearRange] = useState();
  const [activeUsers, setActiveUsers] = useState();
  const [inactiveUsers, setInactiveUsers] = useState();
  const [newRegistration, setNewRegistration] = useState();
  const [questRatio, setQuestRatio] = useState(questRatioInitVals);
  const [percent, setPercent] = useState(percentInitVals);
  const [genderRatio, setGenderRatio] = useState(genderInitVals);
  const [userRatio, setUserRatio] = useState(userRatioInitVals);
  const [activeUsersLen, setActiveUsersLength] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [averageTimeSpent, setAverageTimeSpent] = useState(null);
  const [customDateRange, setCustomDateRange] = useState(
    customDateRangeInitVals
  );
  const [editedUsersList, setEditedUserList] = useState({
    activeUsers: null,
    inActiveUsers: null,
    newRegUsers: null,
  });
  const customDateRangeRef = useRef(customDateRangeInitVals);
  const userlist = useSelector((state) => state.Loginreduce.user_list);
  const quest = useSelector((state) => state?.AdminReducer?.quest_list);
  const averagetimedata = useSelector(
    (state) => state?.AdminReducer?.avgtimeofuserondashboard
  );

  const [activeUserFromDate, setActiveUserFromDate] = useState(null);
  const [activeUserToDate, setactiveUserToDate] = useState(null);
  const [startOfWeek, setstartOfWeek] = useState(null);
  const [endOfWeek, setendOfWeek] = useState(null);
  const [inactiveUserFromDate, setInActiveUserFromDate] = useState(null);
  const [inactiveUserToDate, setInactiveUserToDate] = useState(null);
  const [inActivestartOfWeek, setInActivestartOfWeek] = useState(null);
  const [inActiveendOfWeek, setinActiveendOfWeek] = useState(null);
  const [avgUserFromDate, setavgUserFromDate] = useState(null);
  const [avgUserToDate, setavgUserToDate] = useState(null);
  const [avgstartOfWeek, setavgstartOfWeek] = useState(null);
  const [avgendOfWeek, setavgendOfWeek] = useState(null);

  console.log(activeUserFromDate, activeUserToDate, "activeUserToDate");
  console.log(startOfWeek, endOfWeek, "activeUserToDate");

  useEffect(() => {
    dispatch(Userlist());
    dispatch(getDataQuest());

    setWeekRange(() => {
      return {
        firstWeekStart: moment()
          .startOf("week")
          .subtract(1, "week")
          .add(1, "days")
          .toDate(),
        firstWeekEnd: moment().startOf("week").toDate(),
        secondWeekStart: moment()
          .startOf("week")
          .subtract(14, "days")
          .add(1, "days")
          .toDate(),
        secondWeekEnd: moment().startOf("week").subtract(1, "week").toDate(),
      };
    });
    setMonthRange(() => {
      return {
        firstMonthStart: moment()
          .startOf("month")
          .subtract(1, "month")
          .toDate(),
        firstMonthEnd: moment().startOf("month").subtract(1, "days").toDate(),
        secondMonthStart: moment()
          .startOf("month")
          .subtract(2, "month")
          .toDate(),
        secondMonthEnd: moment()
          .startOf("month")
          .subtract(1, "month")
          .subtract(1, "days")
          .toDate(),
      };
    });
    setYearRange(() => {
      return {
        firstYearStart: moment().startOf("year").subtract(1, "year").toDate(),
        firstYearEnd: moment().startOf("year").subtract(1, "days").toDate(),
        secondYearStart: moment().startOf("year").subtract(2, "year").toDate(),
        secondYearEnd: moment()
          .startOf("year")
          .subtract(1, "year")
          .subtract(1, "days")
          .toDate(),
      };
    });
  }, []);

  const genderRatioFunc = (list, genderCode, genderName) => {
    list?.map((user) => {
      if (user?.gender == genderCode) {
        setGenderRatio((prev) => {
          const isUser = prev[genderName].some(
            (stateUser) => user._id === stateUser._id
          );
          return (
            !isUser && {
              ...prev,
              [genderName]: [...prev[genderName], user],
            }
          );
        });
      }
    });
  };
  const userRatioFunc = (list, verificationCode, verificationName) => {
    list?.map((user, i) => {
      if (user?.admin_approve == verificationCode) {
        setUserRatio((prev) => {
          return {
            ...prev,
            [verificationName]: [...prev[verificationName], user],
          };
        });
      }
    });
  };
  const questRatioFunc = (quest, userlist) => {
    let questUserArr = [];
    quest.forEach((el) => {
      questUserArr?.push(...el?.subscribes?.user_detail);
    });
    const questActiveUser = questUserArr.filter(
      (value, index, self) =>
        index === self.findIndex((el) => el._id === value._id)
    );
    const questinActiveUser = userlist.filter(
      (user) => !questActiveUser.map((aUser) => aUser._id).includes(user._id)
    );
    setQuestRatio({
      sub: questActiveUser,
      nonSub: questinActiveUser,
    });
  };
  const percentFunc = (tab, subTab, list, range, first, second, atVal) => {
    if (tab == "inactiveUsers") {
      var firstArr = list.filter(
        (user) =>
          !(
            range[first.concat("Start")] <=
              moment(user?.[atVal]?.split("T")[0]).toDate() &&
            range[first.concat("End")] >=
              moment(user?.[atVal]?.split("T")[0]).toDate()
          )
      );
      var secondArr = list.filter(
        (user) =>
          !(
            range[second.concat("Start")] <=
              moment(user?.[atVal]?.split("T")[0]).toDate() &&
            range[second.concat("End")] >=
              moment(user?.[atVal]?.split("T")[0]).toDate()
          )
      );
    } else {
      var firstArr = list.filter(
        (user) =>
          range[first.concat("Start")] <=
            moment(user[atVal]?.split("T")[0]).toDate() &&
          range[first.concat("End")] >=
            moment(user[atVal]?.split("T")[0]).toDate()
      );
      var secondArr = list.filter(
        (user) =>
          range[second.concat("Start")] <=
            moment(user[atVal]?.split("T")[0]).toDate() &&
          range[second.concat("End")] >=
            moment(user[atVal]?.split("T")[0]).toDate()
      );
    }

    if (
      firstArr?.length > 0 &&
      secondArr?.length > 0 &&
      firstArr?.length >= secondArr?.length
    ) {
      setPercent((prev) => {
        return {
          ...prev,
          [tab.concat("", subTab)]:
            ((firstArr?.length - secondArr?.length) * 100) / secondArr?.length,
        };
      });
    } else if (
      firstArr?.length > 0 &&
      secondArr?.length == 0 &&
      firstArr?.length >= secondArr?.length
    ) {
      setPercent((prev) => {
        return {
          ...prev,
          [tab.concat("", subTab)]: firstArr?.length * 100,
        };
      });
    } else if (secondArr?.length > 0 && firstArr?.length <= secondArr?.length) {
      setPercent((prev) => {
        return {
          ...prev,
          [tab.concat("", subTab)]:
            ((firstArr?.length - secondArr?.length) * 100) / secondArr?.length,
        };
      });
    }
  };

  const changeValuesWithRange = (givenList, tabName, questList) => {
    console.log(givenList, "given");
    switch (tabName) {
      case "totalUsers":
        setTotalUsers(givenList);
        break;
      case "activeUsers":
        setEditedUserList((prev) => {
          console.log(givenList, "givenList");
          return { ...prev, activeUsers: givenList };
        });
        break;
      case "inActiveUsers":
        setEditedUserList((prev) => {
          console.log(givenList, "givenList");
          return { ...prev, inActiveUsers: givenList };
        });
        break;
      case "newRegUsers":
        setEditedUserList((prev) => {
          return { ...prev, newRegUsers: givenList };
        });
        break;
      case "genderRatio":
        if (givenList?.length > 0) {
          setGenderRatio(genderInitVals);
          let genderArr = [
            [givenList, 2, "female"],
            [givenList, 1, "male"],
            [givenList, 3, "others"],
          ];
          genderArr.forEach((params) => genderRatioFunc(...params));
        } else {
          setGenderRatio(genderInitVals);
        }
        break;
      case "userRatio":
        if (givenList?.length > 0) {
          setUserRatio(userRatioInitVals);
          let userParams = [
            [givenList, 0, "unVerified"],
            [givenList, 1, "verified"],
          ];
          userParams.forEach((params) => userRatioFunc(...params));
        } else {
          setUserRatio(userRatioInitVals);
        }
        break;
      case "questRatio":
        if (givenList?.length > 0 && questList?.length > 0) {
        }
    }
  };

  const questCustomDateRangeFunc = (paramObject) => {
    const generateList = (paramObject, list) => {
      const newUsersArr = list?.filter(
        (user) =>
          moment(
            customDateRangeRef.current[paramObject.tab + "From"]
          ).toDate() <=
            moment(user[paramObject.dateKey]?.split("T")[0]).toDate() &&
          moment(user[paramObject.dateKey]?.split("T")[0]).toDate() <=
            moment(customDateRangeRef.current[paramObject.tab + "To"]).toDate()
      );
      return newUsersArr;
    };
    switch (paramObject.type) {
      case "customDate":
        setCustomDateRange((prev) => {
          return {
            ...prev,
            [paramObject.range]: paramObject.date,
          };
        });
        customDateRangeRef.current[paramObject.range] = paramObject.date;
        if (
          customDateRangeRef.current[paramObject.tab + "From"] &&
          customDateRangeRef.current[paramObject.tab + "To"]
        ) {
          if (paramObject?.list?.length > 0) {
            // const newUsersArr = paramObject?.list?.filter(
            //   (user) =>
            //     moment(
            //       customDateRangeRef.current[paramObject.tab + "From"]
            //     ).toDate() <=
            //       moment(user[paramObject.dateKey]?.split("T")[0]).toDate() &&
            //     moment(user[paramObject.dateKey]?.split("T")[0]).toDate() <=
            //       moment(
            //         customDateRangeRef.current[paramObject.tab + "To"]
            //       ).toDate()
            // );
            const newUsersArr = generateList(paramObject, paramObject.userList);
            const questArr = generateList(paramObject, paramObject.questList);
            changeValuesWithRange(newUsersArr, paramObject.tab, questArr);
          }
        }
        break;
      case "thisWeek":
        if (paramObject?.list?.length > 0) {
          const newUsersArr = paramObject?.list?.filter(
            (user) =>
              moment().startOf("week").add(1, "days").toDate() <=
                moment(user[paramObject.dateKey]).toDate() &&
              moment(user[paramObject.dateKey]).toDate() <= moment().toDate()
          );

          changeValuesWithRange(newUsersArr, paramObject.tab);
        }
        break;
      case "lastWeek":
        if (paramObject?.list?.length > 0) {
          const newUsersArr = paramObject?.list?.filter(
            (user) =>
              moment().startOf("week").subtract(6, "days").toDate() <=
                moment(user.createdAt).toDate() &&
              moment(user.createdAt).toDate() <=
                moment().startOf("week").toDate()
          );
          changeValuesWithRange(newUsersArr, paramObject.tab);
        }
        break;
      case "lastMonth":
        if (paramObject?.list?.length > 0) {
          const newUsersArr = paramObject?.list?.filter(
            (user) =>
              moment().startOf("month").subtract(1, "month").toDate() <=
                moment(user[paramObject.dateKey]).toDate() &&
              moment(user[paramObject.dateKey]).toDate() <=
                moment().startOf("month")
          );
          changeValuesWithRange(newUsersArr, paramObject.tab);
        }
        break;
      default:
      // code block
    }
  };

  const customDateRangeFunc = (customObject) => {
    switch (customObject.type) {
      case "customDate":
        setCustomDateRange((prev) => {
          return {
            ...prev,
            [customObject.range]: customObject.date,
          };
        });
        customDateRangeRef.current[customObject.range] = customObject.date;
        if (
          customDateRangeRef.current[customObject.tab + "From"] &&
          customDateRangeRef.current[customObject.tab + "To"]
        ) {
          if (customObject?.list?.length > 0) {
            const newUsersArr = customObject?.list?.filter(
              (user) =>
                moment(
                  customDateRangeRef.current[customObject.tab + "From"]
                ).toDate() <=
                  moment(
                    // customObject.dateKey == "lastLogin"
                    user[customObject.dateKey]?.split("T")[0]
                    // : user[customObject.dateKey]
                  ).toDate() &&
                moment(
                  // customObject.dateKey == "lastLogin"
                  user[customObject.dateKey]?.split("T")[0]
                  // : user[customObject.dateKey]
                ).toDate() <=
                  moment(
                    customDateRangeRef.current[customObject.tab + "To"]
                  ).toDate()
            );

            changeValuesWithRange(newUsersArr, customObject.tab);
            // switch (customObject.tab) {
            //   case "totalUsers":
            //     setTotalUsers(newUsersArr);
            //     break;
            //   case "activeUsers":
            //     setEditedUserList((prev) => {
            //       return { ...prev, activeUsers: newUsersArr };
            //     });
            //     break;
            //   case "inActiveUsers":
            //     setEditedUserList((prev) => {
            //       return { ...prev, inActiveUsers: newUsersArr };
            //     });
            //     break;
            //   case "newRegUsers":
            //     setEditedUserList((prev) => {
            //       return { ...prev, newRegUsers: newUsersArr };
            //     });
            //     break;
            //   case "genderRatio":
            //     if (newUsersArr.length > 0) {
            //       setGenderRatio(genderInitVals);
            //       let genderArr = [
            //         [newUsersArr, 2, "female"],
            //         [newUsersArr, 1, "male"],
            //         [newUsersArr, 3, "others"],
            //       ];
            //       genderArr.forEach((params) => genderRatioFunc(...params));
            //     } else {
            //       setGenderRatio(genderInitVals);
            //     }
            //     break;
            //   case "userRatio":
            //     if (newUsersArr.length > 0) {
            //       setUserRatio(userRatioInitVals);
            //       let userParams = [
            //         [newUsersArr, 0, "unVerified"],
            //         [newUsersArr, 1, "verified"],
            //       ];
            //       userParams.forEach((params) => userRatioFunc(...params));
            //     } else {
            //       setUserRatio(userRatioInitVals);
            //     }
            // }
          }
        }
        break;
      case "thisWeek":
        if (customObject?.list?.length > 0) {
          const newUsersArr = customObject?.list?.filter(
            (user) =>
              moment().startOf("week").add(1, "days").toDate() <=
                moment(user[customObject.dateKey]).toDate() &&
              moment(user[customObject.dateKey]).toDate() <= moment().toDate()
          );
          changeValuesWithRange(newUsersArr, customObject.tab);
          // switch (customObject.tab) {
          //   case "totalUsers":
          //     setTotalUsers(newUsersArr);
          //     break;
          //   case "activeUsers":
          //     setEditedUserList((prev) => {
          //       return { ...prev, activeUsers: newUsersArr };
          //     });
          //     break;
          //   case "inActiveUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         inActiveUsers: newUsersArr,
          //       };
          //     });
          //     break;
          //   case "newRegUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         newRegUsers: newUsersArr,
          //       };
          //     });
          //     break;
          // }
        }
        break;
      case "lastWeek":
        if (customObject?.list?.length > 0) {
          const newUsersArr = customObject?.list?.filter(
            (user) =>
              moment().startOf("week").subtract(6, "days").toDate() <=
                moment(user.createdAt).toDate() &&
              moment(user.createdAt).toDate() <=
                moment().startOf("week").toDate()
          );
          changeValuesWithRange(newUsersArr, customObject.tab);
          // switch (customObject.tab) {
          //   case "totalUsers":
          //     setTotalUsers(newUsersArr);
          //     break;
          //   case "activeUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         activeUsers: newUsersArr,
          //       };
          //     });
          //     break;
          //   case "inActiveUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         inActiveUsers: newUsersArr,
          //       };
          //     });
          //     break;
          //   case "newRegUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         newRegUsers: newUsersArr,
          //       };
          //     });
          //     break;
          // }
        }
        break;
      case "lastMonth":
        if (customObject?.list?.length > 0) {
          const newUsersArr = customObject?.list?.filter(
            (user) =>
              moment().startOf("month").subtract(1, "month").toDate() <=
                moment(user[customObject.dateKey]).toDate() &&
              moment(user[customObject.dateKey]).toDate() <=
                moment().startOf("month")
          );
          changeValuesWithRange(newUsersArr, customObject.tab);
          // switch (customObject.tab) {
          //   case "totalUsers":
          //     setTotalUsers(newUsersArr);
          //     break;
          //   case "activeUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         activeUsers: newUsersArr,
          //       };
          //     });
          //     break;
          //   case "inActiveUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         inActiveUsers: newUsersArr,
          //       };
          //     });
          //     break;
          //   case "newRegUsers":
          //     setEditedUserList((prev) => {
          //       return {
          //         ...prev,
          //         newRegUsers: newUsersArr,
          //       };
          //     });
          //     break;
          // }
        }
        break;
      default:
      // code block
    }
  };
  // const inActiveFunc = (userArr, timeRange, first, second) => {
  //   const firstWeekArr = userArr.filter(
  //     (user) =>
  //       !(
  //         timeRange[first.concat("Start")] >=
  //           moment(user?.lastLogin?.split("T")[0]).toDate() &&
  //         timeRange[first.concat("End")] <=
  //           moment(user?.lastLogin?.split("T")[0]).toDate()
  //       )
  //   );

  //   const secondWeekArr = userArr.filter(
  //     (user) =>
  //       !(
  //         timeRange[second.concat("Start")] >=
  //           moment(user?.lastLogin?.split("T")[0]).toDate() &&
  //         timeRange[second.concat("End")] <=
  //           moment(user?.lastLogin?.split("T")[0]).toDate()
  //       )
  //   );
  // };

  useEffect(() => {
    if (userlist?.length > 0) {
      setTotalUsers(userlist);
    }

    if (userlist?.length > 0 && weekRange && monthRange && yearRange) {
      setPercent(percentInitVals);
      const activeUsrs = userlist?.filter((user) => user?.lastLogin);
      userlist.forEach((user) => user?.lastLogin);
      setActiveUsersLength(() => {
        return activeUsrs.filter(
          (user) =>
            moment().startOf("month").toDate() <=
              moment(user?.lastLogin?.split("T")).toDate() &&
            moment().toDate() <= moment(user?.lastLogin?.split("T")).toDate()
        );
      });

      const InactiveWeekRange = activeUsrs.filter(
        (user) =>
          weekRange.secondWeekStart <=
            moment(user?.lastLogin?.split("T")[0]).toDate() &&
          weekRange.firstWeekEnd >=
            moment(user?.lastLogin?.split("T")[0]).toDate()
      );

      const InactiveMonthRange = activeUsrs.filter(
        (user) =>
          monthRange.secondMonthStart <=
            moment(user?.lastLogin?.split("T")[0]).toDate() &&
          monthRange.firstMonthEnd >=
            moment(user?.lastLogin?.split("T")[0]).toDate()
      );
      const InactiveYearRange = activeUsrs.filter(
        (user) =>
          yearRange.secondYearStart <=
            moment(user.lastLogin?.split("T")[0]).toDate() &&
          yearRange.firstYearEnd >=
            moment(user.lastLogin?.split("T")[0]).toDate()
      );
      setInactiveUsers([
        ...InactiveWeekRange,
        ...InactiveMonthRange,
        ...InactiveYearRange,
      ]);
      setActiveUsers(activeUsrs);
      setNewRegistration(() => {
        return userlist.filter(
          (user) =>
            moment().startOf("month").toDate() <=
              moment(user?.createdAt).toDate() &&
            moment(user?.createdAt).toDate() <= moment().toDate()
        );
      });
      let functionParamArr = [
        [
          "totalUsers",
          "wow",
          userlist,
          weekRange,
          "firstWeek",
          "secondWeek",
          "createdAt",
        ],
        [
          "totalUsers",
          "mom",
          userlist,
          monthRange,
          "firstMonth",
          "secondMonth",
          "createdAt",
        ],
        [
          "totalUsers",
          "yoy",
          userlist,
          yearRange,
          "firstYear",
          "secondYear",
          "createdAt",
        ],
        [
          "activeUsers",
          "wow",
          activeUsrs,
          weekRange,
          "firstWeek",
          "secondWeek",
          "lastLogin",
        ],
        [
          "activeUsers",
          "mom",
          activeUsrs,
          monthRange,
          "firstMonth",
          "secondMonth",
          "lastLogin",
        ],
        [
          "activeUsers",
          "yoy",
          activeUsrs,
          yearRange,
          "firstYear",
          "secondYear",
          "lastLogin",
        ],
        [
          "inactiveUsers",
          "wow",
          InactiveWeekRange,
          weekRange,
          "firstWeek",
          "secondWeek",
          "lastLogin",
        ],
        [
          "inactiveUsers",
          "mom",
          InactiveMonthRange,
          monthRange,
          "firstMonth",
          "secondMonth",
          "lastLogin",
        ],
        [
          "inactiveUsers",
          "yoy",
          InactiveYearRange,
          yearRange,
          "firstYear",
          "secondYear",
          "lastLogin",
        ],
      ];
      functionParamArr.forEach((params) => {
        percentFunc(...params);
      });
    }

    if (userlist?.length > 0) {
      setGenderRatio(genderInitVals);
      setUserRatio(userRatioInitVals);
      let genderFuncParams = [
        [userlist, 2, "female"],
        [userlist, 1, "male"],
        [userlist, 3, "others"],
      ];
      let userRatioParams = [
        [userlist, 0, "unVerified"],
        [userlist, 1, "verified"],
      ];
      genderFuncParams.forEach((params) => {
        genderRatioFunc(...params);
      });

      userRatioParams.forEach((params) => {
        userRatioFunc(...params);
      });
    }
  }, [userlist]);

  useEffect(() => {
    if (quest?.length > 0 && userlist?.length > 0) {
      questRatioFunc(quest, userlist);
    }
  }, [quest, userlist]);

  useEffect(() => {
    const fetchdata = async () => {
      if (
        activeUserToDate ||
        endOfWeek ||
        inactiveUserToDate ||
        inActiveendOfWeek || avgUserToDate || avgendOfWeek
      ) {
        let data = await dispatch(
          avgtimeuser({
            activeUserFromDate,
            activeUserToDate,
            startOfWeek,
            endOfWeek,
            inactiveUserToDate,
            inactiveUserFromDate,
            inActiveendOfWeek,
            inActivestartOfWeek,
            avgUserToDate,
            avgUserFromDate,
            avgendOfWeek,
            avgstartOfWeek,

          })
        );
        setAverageTimeSpent(data);
      } else {
        let data = await dispatch(avgtimeuser());
        setAverageTimeSpent(data);
      }
    };

    fetchdata();
  }, [activeUserToDate, endOfWeek, inactiveUserToDate, inActiveendOfWeek ,avgUserToDate , avgendOfWeek]);

  console.log(
    averageTimeSpent?.data?.data,
    "averageTimeSpentaverageTimeSpentaverageTimeSpent"
  );

  const getStartAndEndOfWeek = (userType) => {
    const today = new Date();
    const startOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 1)
    );
    const endOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 7)
    );

    if (userType == "active") {
      setstartOfWeek(startOfWeek.toISOString().split("T")[0]);
      setendOfWeek(endOfWeek.toISOString().split("T")[0]);
    }

    if (userType == "inactive") {
      setinActiveendOfWeek(endOfWeek.toISOString().split("T")[0]);
      setInActivestartOfWeek(startOfWeek.toISOString().split("T")[0]);
    }
    if (userType == "avg") {
      setavgendOfWeek(endOfWeek.toISOString().split("T")[0]);
      setavgstartOfWeek(startOfWeek.toISOString().split("T")[0]);
    }

    // return {
    //   startOfWeek: startOfWeek.toISOString().split('T')[0], // Format as YYYY-MM-DD
    //   endOfWeek: endOfWeek.toISOString().split('T')[0] // Format as YYYY-MM-DD
    // };
  };

  const getLastWeek = (userType) => {
    const now = new Date();

    const currentDayOfWeek = now.getDay();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - currentDayOfWeek - 7);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    if (userType == "active") {
      setstartOfWeek(startOfWeek.toISOString().split("T")[0]);
      setendOfWeek(endOfWeek.toISOString().split("T")[0]);
    }
    if (userType == "inactive") {
      setinActiveendOfWeek(endOfWeek.toISOString().split("T")[0]);
      setInActivestartOfWeek(startOfWeek.toISOString().split("T")[0]);
    }
    if (userType == "avg") {
      setavgendOfWeek(endOfWeek.toISOString().split("T")[0]);
      setavgstartOfWeek(startOfWeek.toISOString().split("T")[0]);
    }
  };

  const getLastMonth = (userType) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);

    if (userType == "active") {
      setstartOfWeek(startOfMonth.toISOString().split("T")[0]);
      setendOfWeek(endOfMonth.toISOString().split("T")[0]);
    }
    if (userType == "inactive") {
      setinActiveendOfWeek(endOfMonth.toISOString().split("T")[0]);
      setInActivestartOfWeek(startOfMonth.toISOString().split("T")[0]);
    }
    if (userType == "avg") {
      setavgendOfWeek(endOfMonth.toISOString().split("T")[0]);
      setavgstartOfWeek(startOfMonth.toISOString().split("T")[0]);
    }
  };

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Dashboard</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-card-four">
              <div className="widget-content">
                <div className="w-header">
                  <div className="w-info">
                    <h6 className="value">Total Users</h6>
                  </div>
                  <div className="task-action">
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        role="button"
                        id="expenses"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-more-horizontal"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="19" cy="12" r="1"></circle>
                          <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                      </a>

                      <div
                        className="dropdown-menu left date_picker"
                        aria-labelledby="expenses"
                        style={{ willChange: "transform" }}
                      >
                        <a className="d-flex justify-content-between dropdown-item">
                          <div className="form-group mb-0">
                            <label for="" className="ms-2">
                              From
                            </label>
                            <input
                              onChange={(e) =>
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "totalUsers",
                                  date: e.target.value,
                                  range: "totalUsersFrom",
                                  dateKey: "createdAt",
                                  list: userlist?.length > 0 && userlist,
                                })
                              }
                              id="basicFlatpickr"
                              value={customDateRange.totalUsersFrom}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              max={customDateRange.totalUsersTo}
                            />
                          </div>
                          <div className="form-group mb-0">
                            <label for="">To</label>
                            <input
                              onChange={(e) =>
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "totalUsers",
                                  date: e.target.value,
                                  range: "totalUsersTo",
                                  dateKey: "createdAt",
                                  list: userlist?.length > 0 && userlist,
                                })
                              }
                              id="basicFlatpickr1"
                              value={customDateRange.totalUsersTo}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              min={customDateRange.totalUsersFrom}
                            />
                          </div>
                        </a>

                        <a
                          onClick={() =>
                            customDateRangeFunc({
                              type: "thisWeek",
                              tab: "totalUsers",
                              dateKey: "createdAt",
                              list: userlist?.length > 0 && userlist,
                            })
                          }
                          className="dropdown-item"
                        >
                          This Week
                        </a>
                        <a
                          onClick={() =>
                            customDateRangeFunc({
                              type: "lastWeek",
                              tab: "totalUsers",
                              dateKey: "createdAt",
                              list: userlist?.length > 0 && userlist,
                            })
                          }
                          className="dropdown-item"
                        >
                          Last Week
                        </a>
                        <a
                          onClick={() =>
                            customDateRangeFunc({
                              type: "lastMonth",
                              tab: "totalUsers",
                              dateKey: "createdAt",
                              list: userlist?.length > 0 && userlist,
                            })
                          }
                          className="dropdown-item"
                        >
                          Last Month
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-content">
                  <div className="w-info">
                    <p className="value">{totalUsers?.length} Users</p>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">WOW</h6>
                  <div className="w-progress-stats">
                    {percent?.totalUserswow.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(percent?.totalUserswow * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(percent?.totalUserswow * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{
                          width: "57%",
                          ariaValuenow: "57",
                          ariaValuemin: "0",
                          ariaValuemax: "100",
                        }}
                      ></div> */}

                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(percent?.totalUserswow * 100) / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">MOM</h6>
                  <div className="w-progress-stats">
                    {percent?.totalUsersmom.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(percent?.totalUsersmom * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(percent?.totalUsersmom * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-danger position-relative"
                        role="progressbar"
                        style={{
                          width: "37%",
                          ariaValuenow: "37",
                          ariaValuemin: "0",
                          ariaValuemax: "100",
                        }}
                      ></div> */}

                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(percent?.totalUsersmom * 100) / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">YOY</h6>
                  <div className="w-progress-stats">
                    {percent?.totalUsersyoy.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(percent?.totalUsersyoy * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(percent?.totalUsersyoy * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "87%" }}
                        aria-valuenow="87"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(percent?.totalUsersyoy * 100) / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-card-four">
              <div className="widget-content">
                <div className="w-header">
                  <div className="w-info">
                    <h6 className="value">Active Users</h6>
                  </div>
                  <div className="task-action">
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        role="button"
                        id="expenses"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-more-horizontal"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="19" cy="12" r="1"></circle>
                          <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                      </a>

                      <div
                        className="dropdown-menu left date_picker"
                        aria-labelledby="expenses"
                        style={{ willChange: "transform" }}
                      >
                        <a className="d-flex justify-content-between dropdown-item">
                          <div className="form-group mb-0">
                            <label for="" className="ms-2">
                              From
                            </label>
                            <input
                              onChange={(e) => {
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "activeUsers",
                                  date: e.target.value,
                                  dateKey: "lastLogin",
                                  range: "activeUsersFrom",
                                  list:
                                    activeUsersLen?.length > 0 &&
                                    activeUsersLen,
                                });
                                setstartOfWeek(e.target.value);
                              }}
                              id="basicFlatpickr"
                              value={customDateRange.activeUsersFrom}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              max={customDateRange.activeUsersTo}
                            />
                          </div>
                          <div className="form-group mb-0">
                            <label for="">To</label>
                            <input
                              onChange={(e) => {
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "activeUsers",
                                  date: e.target.value,
                                  dateKey: "lastLogin",
                                  range: "activeUsersTo",
                                  list:
                                    activeUsersLen?.length > 0 &&
                                    activeUsersLen,
                                });
                                setendOfWeek(e.target.value);
                              }}
                              id="basicFlatpickr1"
                              value={customDateRange.activeUsersTo}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              min={customDateRange.activeUsersFrom}
                            />
                          </div>
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() => {
                            customDateRangeFunc({
                              type: "thisWeek",
                              tab: "activeUsers",
                              dateKey: "lastLogin",
                              list:
                                activeUsersLen?.length > 0 && activeUsersLen,
                            });
                            getStartAndEndOfWeek("active");
                          }}
                        >
                          This Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            customDateRangeFunc({
                              type: "lastWeek",
                              tab: "activeUsers",
                              dateKey: "lastLogin",
                              list:
                                activeUsersLen?.length > 0 && activeUsersLen,
                            });
                            getLastWeek("active");
                          }}
                        >
                          Last Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            customDateRangeFunc({
                              type: "lastMonth",
                              tab: "activeUsers",
                              dateKey: "lastLogin",
                              list:
                                activeUsersLen?.length > 0 && activeUsersLen,
                            });

                            getLastMonth("active");
                          }}
                        >
                          Last Month
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-content">
                  <div className="w-info">
                    <p className="value">
                      {/* {editedUsersList?.activeUsers
                        ? editedUsersList?.activeUsers?.length
                        : activeUsersLen?.length} */}{" "}
                      {averageTimeSpent?.data?.data?.total_active_users} Users
                    </p>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">WOW</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.wow_active_users?.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(averageTimeSpent?.data?.data?.wow_active_users * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(averageTimeSpent?.data?.data?.wow_active_users * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "57%" }}
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(averageTimeSpent?.data?.data?.wow_active_users * 100) / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">MOM</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.mom_active_users?.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(averageTimeSpent?.data?.data?.mom_active_users * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(averageTimeSpent?.data?.data?.mom_active_users * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-danger position-relative"
                        role="progressbar"
                        style={{ width: "37%" }}
                        aria-valuenow="37"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(averageTimeSpent?.data?.data?.mom_active_users * 100) / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">YOY</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.yoy_active_users?.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(averageTimeSpent?.data?.data?.yoy_active_users * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(averageTimeSpent?.data?.data?.yoy_active_users * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "87%" }}
                        aria-valuenow="87"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(averageTimeSpent?.data?.data?.yoy_active_users * 100) / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-card-four">
              <div className="widget-content">
                <div className="w-header">
                  <div className="w-info">
                    <h6 className="value">Inactive Users</h6>
                  </div>
                  <div className="task-action">
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="expenses"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-more-horizontal"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="19" cy="12" r="1"></circle>
                          <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                      </a>

                      <div
                        className="dropdown-menu left date_picker"
                        aria-labelledby="expenses"
                        style={{ willCchange: "transform" }}
                      >
                        <a className="d-flex justify-content-between dropdown-item">
                          <div className="form-group mb-0">
                            <label for="" className="ms-2">
                              From
                            </label>
                            <input
                              onChange={(e) => {
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "inActiveUsers",
                                  date: e.target.value,
                                  dateKey: "lastLogin",
                                  range: "inActiveUsersFrom",
                                  list:
                                    inactiveUsers?.length > 0 && inactiveUsers,
                                });
                                setInActivestartOfWeek(e.target.value);
                              }}
                              id="basicFlatpickr"
                              value={customDateRange.inActiveUsersFrom}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              max={customDateRange.inActiveUsersTo}
                            />
                          </div>
                          <div className="form-group mb-0">
                            <label for="">To</label>
                            <input
                              onChange={(e) => {
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "inActiveUsers",
                                  date: e.target.value,
                                  dateKey: "lastLogin",
                                  range: "inActiveUsersTo",
                                  list:
                                    inactiveUsers?.length > 0 && inactiveUsers,
                                });

                                setinActiveendOfWeek(e.target.value);
                              }}
                              id="basicFlatpickr1"
                              value={customDateRange.inActiveUsersTo}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              min={customDateRange.inActiveUsersFrom}
                            />
                          </div>
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() => {
                            customDateRangeFunc({
                              type: "thisWeek",
                              tab: "inActiveUsers",
                              dateKey: "lastLogin",
                              list: inactiveUsers?.length > 0 && inactiveUsers,
                            });

                            getStartAndEndOfWeek("inactive");
                          }}
                        >
                          This Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            customDateRangeFunc({
                              type: "lastWeek",
                              tab: "inActiveUsers",
                              dateKey: "lastLogin",
                              list: inactiveUsers?.length > 0 && inactiveUsers,
                            });
                            getLastWeek("inactive");
                          }}
                        >
                          Last Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            customDateRangeFunc({
                              type: "lastMonth",
                              tab: "inActiveUsers",
                              dateKey: "lastLogin",
                              list: inactiveUsers?.length > 0 && inactiveUsers,
                            });
                            getLastMonth("inactive");
                          }}
                        >
                          Last Month
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-content">
                  <div className="w-info">
                    <p className="value">
                      {/* {editedUsersList?.inActiveUsers
                        ? editedUsersList?.inActiveUsers?.length
                        : inactiveUsers?.length}{" "} */}
                      {averageTimeSpent?.data?.data?.total_inactive_users} Users
                    </p>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">WOW</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.wow_inactive_users?.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(averageTimeSpent?.data?.data?.wow_inactive_users * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(averageTimeSpent?.data?.data?.wow_inactive_users * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "57%" }}
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(averageTimeSpent?.data?.data?.wow_inactive_users * 100) / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="progress_bar">
                  <h6 className="mb-1">MOM</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.mom_inactive_users?.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(averageTimeSpent?.data?.data?.mom_inactive_users * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(averageTimeSpent?.data?.data?.mom_inactive_users * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "57%" }}
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(averageTimeSpent?.data?.data?.mom_inactive_users * 100) / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="progress_bar">
                  <h6 className="mb-1">YOY</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.yoy_inactive_users?.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(averageTimeSpent?.data?.data?.yoy_inactive_users * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(averageTimeSpent?.data?.data?.yoy_inactive_users * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "57%" }}
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(averageTimeSpent?.data?.data?.yoy_inactive_users * 100) / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-card-four">
              <div className="widget-content">
                <div className="w-header">
                  <div className="w-info">
                    <h6 className="value">New Registration</h6>
                  </div>
                  <div className="task-action">
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="expenses"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-more-horizontal"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="19" cy="12" r="1"></circle>
                          <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                      </a>

                      <div
                        className="dropdown-menu left date_picker"
                        aria-labelledby="expenses"
                        style={{ willChange: "transform" }}
                      >
                        <a className="d-flex justify-content-between dropdown-item">
                          <div className="form-group mb-0">
                            <label for="" className="ms-2">
                              From
                            </label>
                            <input
                              onChange={(e) =>
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "newRegUsers",
                                  date: e.target.value,
                                  dateKey: "createdAt",
                                  range: "newRegUsersFrom",
                                  list:
                                    newRegistration?.length > 0 &&
                                    newRegistration,
                                })
                              }
                              id="basicFlatpickr"
                              value={customDateRange.newRegFrom}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              max={customDateRange.newRegTo}
                            />
                          </div>
                          <div className="form-group mb-0">
                            <label for="">To</label>
                            <input
                              onChange={(e) =>
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "newRegUsers",
                                  date: e.target.value,
                                  dateKey: "createdAt",
                                  range: "newRegUsersTo",
                                  list:
                                    newRegistration?.length > 0 &&
                                    newRegistration,
                                })
                              }
                              id="basicFlatpickr1"
                              value={customDateRange.newRegTo}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              min={customDateRange.newRegFrom}
                            />
                          </div>
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() =>
                            customDateRangeFunc({
                              type: "thisWeek",
                              tab: "newRegUsers",
                              dateKey: "createdAt",
                              list:
                                newRegistration?.length > 0 && newRegistration,
                            })
                          }
                        >
                          This Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            customDateRangeFunc({
                              type: "lastWeek",
                              tab: "newRegUsers",
                              dateKey: "createdAt",
                              list:
                                newRegistration?.length > 0 && newRegistration,
                            })
                          }
                        >
                          Last Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            customDateRangeFunc({
                              type: "lastMonth",
                              tab: "newRegUsers",
                              dateKey: "createdAt",
                              list:
                                newRegistration?.length > 0 && newRegistration,
                            })
                          }
                        >
                          Last Month
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-content">
                  <div className="w-info">
                    <p className="value">
                      {editedUsersList?.newRegUsers
                        ? editedUsersList?.newRegUsers?.length
                        : newRegistration?.length}{" "}
                      Users
                    </p>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">WOW</h6>
                  <div className="w-progress-stats">
                    {percent?.totalUserswow.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(percent?.totalUserswow * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(percent?.totalUserswow * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "57%" }}
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(percent?.totalUserswow * 100) / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">MOM</h6>
                  <div className="w-progress-stats">
                    {percent?.totalUsersmom.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(percent?.totalUsersmom * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(percent?.totalUsersmom * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-danger position-relative"
                        role="progressbar"
                        style={{ width: "37%" }}
                        aria-valuenow="37"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(percent?.totalUsersmom * 100) / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">YOY</h6>
                  <div className="w-progress-stats">
                    {percent?.totalUsersyoy.toString().startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={(Math.round(percent?.totalUsersyoy * 100) / 100)
                          .toString()
                          .slice(1)}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={Math.round(percent?.totalUsersyoy * 100) / 100}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "87%" }}
                        aria-valuenow="87"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {Math.round(percent?.totalUsersyoy * 100) / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-3 col-md-3 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-card-four">
              <div className="widget-content">
                <div className="w-header">
                  <div className="w-info">
                    <h6 className="value">
                      Average Time Spent in The Application Per User
                    </h6>
                  </div>
                  <div className="task-action">
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="expenses"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-more-horizontal"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="19" cy="12" r="1"></circle>
                          <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                      </a>

                      <div
                        className="dropdown-menu left date_picker"
                        aria-labelledby="expenses"
                        style={{ willChange: "transform" }}
                      >
                        <a className="d-flex justify-content-between dropdown-item">
                          <div className="form-group mb-0">
                            <label for="" className="ms-2">
                              From
                            </label>
                            <input
                              onChange={(e) => {
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "newRegUsers",
                                  date: e.target.value,
                                  dateKey: "createdAt",
                                  range: "newRegUsersFrom",
                                  list:
                                    newRegistration?.length > 0 &&
                                    newRegistration,
                                });
                                setavgstartOfWeek(e.target.value);
                              }}
                              id="basicFlatpickr"
                              value={customDateRange.newRegFrom}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              max={customDateRange.newRegTo}
                            />
                          </div>
                          <div className="form-group mb-0">
                            <label for="">To</label>
                            <input
                              onChange={(e) => {
                                customDateRangeFunc({
                                  type: "customDate",
                                  tab: "newRegUsers",
                                  date: e.target.value,
                                  dateKey: "createdAt",
                                  range: "newRegUsersTo",
                                  list:
                                    newRegistration?.length > 0 &&
                                    newRegistration,
                                });
                                setavgendOfWeek(e.target.value);
                              }}
                              id="basicFlatpickr1"
                              value={customDateRange.newRegTo}
                              className="form-control flatpickr flatpickr-input active"
                              type="date"
                              placeholder="Select Date.."
                              min={customDateRange.newRegFrom}
                            />
                          </div>
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() =>
                            {customDateRangeFunc({
                              type: "thisWeek",
                              tab: "newRegUsers",
                              dateKey: "createdAt",
                              list:
                                newRegistration?.length > 0 && newRegistration,
                            })
                            getStartAndEndOfWeek("avg")}
                          }
                        >
                          This Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            {customDateRangeFunc({
                              type: "lastWeek",
                              tab: "newRegUsers",
                              dateKey: "createdAt",
                              list:
                                newRegistration?.length > 0 && newRegistration,
                            })
                            getLastWeek("avg")}
                          }
                        >
                          Last Week
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            {customDateRangeFunc({
                              type: "lastMonth",
                              tab: "newRegUsers",
                              dateKey: "createdAt",
                              list:
                                newRegistration?.length > 0 && newRegistration,
                            })
                            getLastMonth("avg")}
                          }
                        >
                          Last Month
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-content">
                  <div className="w-info">
                    <p className="value">
                      {averageTimeSpent?.data?.data?.total_hours[0] == "0"
                        ? averageTimeSpent?.data?.data?.total_minutes + " Min"
                        : averageTimeSpent?.data?.data?.total_hours +
                          " Hours"}{" "}
                    </p>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">WOW</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.weekly_avg
                      .toString()
                      .startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={averageTimeSpent?.data?.data?.weekly_avg}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={averageTimeSpent?.data?.data?.weekly_avg}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "57%" }}
                        aria-valuenow="57"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {averageTimeSpent?.data?.data?.weekly_avg / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">MOM</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.weekly_avg
                      .toString()
                      .startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={averageTimeSpent?.data?.data?.monthly_avg}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={averageTimeSpent?.data?.data?.monthly_avg}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-danger position-relative"
                        role="progressbar"
                        style={{ width: "37%" }}
                        aria-valuenow="37"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {averageTimeSpent?.data?.data?.monthly_avg / 100 +
                            "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="progress_bar">
                  <h6 className="mb-1">YOY</h6>
                  <div className="w-progress-stats">
                    {averageTimeSpent?.data?.data?.weekly_avg
                      .toString()
                      .startsWith("-") ? (
                      <ProgressBar
                        variant="danger"
                        now={averageTimeSpent?.data?.data?.yearly_avg}
                      />
                    ) : (
                      <ProgressBar
                        variant="success"
                        now={averageTimeSpent?.data?.data?.yearly_avg}
                      />
                    )}
                    {/* <div
                        className="progress-bar bg-success  position-relative"
                        role="progressbar"
                        style={{ width: "87%" }}
                        aria-valuenow="87"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div> */}
                    <div className="">
                      <div className="w-icon">
                        <p>
                          {averageTimeSpent?.data?.data?.yearly_avg / 100 + "%"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-chart-two">
              <div className="d-flex justify-content-between align-items-center">
                <div className="widget-heading">
                  <h5 className="">Gender Ratio</h5>
                </div>
                {/* <div className="task-action">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      role="button"
                      id="expenses"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>

                    <div
                      className="dropdown-menu left date_picker"
                      aria-labelledby="expenses"
                      style={{ willChange: "transform" }}
                    >
                      <a className="d-flex justify-content-between dropdown-item">
                        <div className="form-group mb-0">
                          <label for="" className="ms-2">
                            From
                          </label>
                          <input
                            onChange={(e) =>
                              customDateRangeFunc({
                                type: "customDate",
                                tab: "genderRatio",
                                date: e.target.value,
                                range: "genderRatioFrom",
                                dateKey: "createdAt",
                                list: userlist?.length > 0 && userlist,
                              })
                            }
                            id="basicFlatpickr"
                            value={customDateRange?.genderRatioFrom}
                            className="form-control flatpickr flatpickr-input active"
                            type="date"
                            placeholder="Select Date.."
                            max={customDateRange?.genderRatioTo}
                          />
                        </div>
                        <div className="form-group mb-0">
                          <label for="">To</label>
                          <input
                            onChange={(e) =>
                              customDateRangeFunc({
                                type: "customDate",
                                tab: "genderRatio",
                                date: e.target.value,
                                range: "genderRatioTo",
                                dateKey: "createdAt",
                                list: userlist?.length > 0 && userlist,
                              })
                            }
                            id="basicFlatpickr1"
                            value={customDateRange?.genderRatioTo}
                            className="form-control flatpickr flatpickr-input active"
                            type="date"
                            placeholder="Select Date.."
                            min={customDateRange?.genderRatioFrom}
                          />
                        </div>
                      </a>

                      <a
                        className="dropdown-item"
                        onClick={() =>
                          customDateRangeFunc({
                            type: "thisWeek",
                            tab: "genderRatio",
                            dateKey: "createdAt",
                            list: userlist?.length > 0 && userlist,
                          })
                        }
                      >
                        This Week
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          customDateRangeFunc({
                            type: "lastWeek",
                            tab: "genderRatio",
                            dateKey: "createdAt",
                            list: userlist?.length > 0 && userlist,
                          })
                        }
                      >
                        Last Week
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          customDateRangeFunc({
                            type: "lastMonth",
                            tab: "genderRatio",
                            dateKey: "createdAt",
                            list: userlist?.length > 0 && userlist,
                          })
                        }
                      >
                        Last Month
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="widget-content">
                <div id="chart-2" className="">
                  {/* {genderRatio && ( */}
                  <PieCharts
                    series={[
                      // averageTimeSpent?.data?.data?.gender_count,
                      averageTimeSpent?.data?.data?.total_females,
                      averageTimeSpent?.data?.data?.total_males,
                      averageTimeSpent?.data?.data?.total_others_gender,
                    ]}
                    labels={["Female", "Male", "Others"]}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-chart-two">
              <div className="d-flex justify-content-between align-items-center">
                <div className="widget-heading">
                  <h5 className="">User Ratio</h5>
                </div>
                {/* <div className="task-action">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="expenses"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>

                    <div
                      className="dropdown-menu left date_picker"
                      aria-labelledby="expenses"
                      style={{ willChange: "transform" }}
                    >
                      <a className="d-flex justify-content-between dropdown-item">
                        <div className="form-group mb-0">
                          <label for="" className="ms-2">
                            From
                          </label>
                          <input
                            onChange={(e) =>
                              customDateRangeFunc({
                                type: "customDate",
                                tab: "userRatio",
                                date: e.target.value,
                                range: "userRatioFrom",
                                dateKey: "createdAt",
                                list: userlist?.length > 0 && userlist,
                              })
                            }
                            id="basicFlatpickr"
                            value={customDateRange?.userRatioFrom}
                            className="form-control flatpickr flatpickr-input active"
                            type="date"
                            placeholder="Select Date.."
                          />
                        </div>
                        <div className="form-group mb-0">
                          <label for="">To</label>
                          <input
                            onChange={(e) =>
                              customDateRangeFunc({
                                type: "customDate",
                                tab: "userRatio",
                                date: e.target.value,
                                range: "userRatioTo",
                                dateKey: "createdAt",
                                list: userlist?.length > 0 && userlist,
                              })
                            }
                            id="basicFlatpickr1"
                            value={customDateRange?.userRatioTo}
                            className="form-control flatpickr flatpickr-input active"
                            type="date"
                            placeholder="Select Date.."
                          />
                        </div>
                      </a>

                      <a
                        className="dropdown-item"
                        onClick={() =>
                          customDateRangeFunc({
                            type: "thisWeek",
                            tab: "userRatio",
                            dateKey: "createdAt",
                            list:
                              newRegistration?.length > 0 && newRegistration,
                          })
                        }
                      >
                        This Week
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          customDateRangeFunc({
                            type: "lastWeek",
                            tab: "userRatio",
                            dateKey: "createdAt",
                            list:
                              newRegistration?.length > 0 && newRegistration,
                          })
                        }
                      >
                        Last Week
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          customDateRangeFunc({
                            type: "lastMonth",
                            tab: "userRatio",
                            dateKey: "createdAt",
                            list:
                              newRegistration?.length > 0 && newRegistration,
                          })
                        }
                      >
                        Last Month
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="widget-content">
                <div id="chart-3" className="">
                  {userRatio && (
                    <PieCharts
                      series={[
                        averageTimeSpent?.data?.data?.total_verified,
                        averageTimeSpent?.data?.data?.total_unverified,
                      ]}
                      labels={["Verified", "Unverified"]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div className="widget widget-chart-two">
              <div className="d-flex justify-content-between align-items-center">
                <div className="widget-heading">
                  <h5 className="">Quest Ratio</h5>
                </div>
                {/* <div className="task-action">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="expenses"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>

                    <div
                      className="dropdown-menu left date_picker"
                      aria-labelledby="expenses"
                      style={{ willChange: "transform" }}
                    >
                      <a className="d-flex justify-content-between dropdown-item">
                        <div className="form-group mb-0">
                          <label for="" className="ms-2">
                            From
                          </label>
                          <input
                            id="basicFlatpickr"
                            value="2022-09-04"
                            className="form-control flatpickr flatpickr-input active"
                            type="date"
                            placeholder="Select Date.."
                          />
                        </div>
                        <div className="form-group mb-0">
                          <label for="">To</label>
                          <input
                            id="basicFlatpickr1"
                            value="2022-09-04"
                            className="form-control flatpickr flatpickr-input active"
                            type="date"
                            placeholder="Select Date.."
                          />
                        </div>
                      </a>

                      <a className="dropdown-item" href="javascript:void(0);">
                        This Week
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        Last Week
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        Last Month
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="widget-content">
                <div id="chart-4" className="">
                  {questRatio && (
                    <QuiestPieCharts
                      series={[
                        averageTimeSpent?.data?.data?.total_subscribed,
                        averageTimeSpent?.data?.data?.total_not_subscribed,
                        // averageTimeSpent?.data?.data?.total_quests
                      ]}
                      labels={["Subscribed", "Not Subscribed"]}
                      totalQuests={averageTimeSpent?.data?.data?.total_quests}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}

      {/* <!--  BEGIN FOOTER  --> */}

      {/* <!--  END FOOTER  --> */}
      {/* // </div> */}
    </Master>
  );
};
