import { ADMIN_LIST, ACCESS_LIST, SUPPORT_LIST, USER_LIST } from "../Constant";

const initialValues = {
  user_list: [],
  support_list: [],
  access_list: [],
  admin_list: [],
  
};

const Loginreducer = (state = initialValues, action) => {
  switch (action.type) {
    case USER_LIST: {
      return {
        ...state,
        user_list: action.payload,
      };
    }
    case SUPPORT_LIST: {
      return {
        ...state,
        support_list: action.payload,
      };
    }
    case ACCESS_LIST: {
      return {
        ...state,
        access_list: action.payload,
      };
    }
    case ADMIN_LIST: {
      return {
        ...state,
        admin_list: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Loginreducer;
