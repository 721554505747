import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics, getInterests } from "../../Redux/Action/AdminPannel";
import Master from "../Include/Master";
import AgeCountChart from "./AgeCountChart";
import BarChart from "./BarChart";
import ColumnChart from "./ColumnChart";
import DeleteAccount from "./DeleteAccount";
import InterestChart from "./InterestChart";
import PerCountryChart from "./PerCountryChart";
import Delete1 from "./Delete1";
const Analytics = () => {
  const [gameGenre, setGameGenre] = useState();
  const [dataGameG, setDataGameG] = useState();
  const [countryGraph, setCountryGraph] = useState();
  const [countryData, setCountryData] = useState();
  const [interestGraph, setInterestGraph] = useState();
  const [interestData, setInterestData] = useState();
  const [ageGraph, setAgeGraph] = useState();
  const [ageData, setAgeData] = useState();
  const [originGraphData, setOriginGraphData] = useState();
  const [originData, setOriginData] = useState();
  const [numberofuserList, setnumberofuserList] = useState();
  const [numberofuserGraphData, setnumberofuserGraphData] = useState();

  const [deletedGraphData, setdeletedGraphData] = useState();
  const [deletedData, setdeletedData] = useState();
  const dispatch = useDispatch();
  const analyticsList = useSelector(
    (state) => state?.AdminReducer?.analytic_list
  );

  const countryList = useSelector((state) => state?.AdminReducer?.country_list);

  const InterstList = useSelector(
    (state) => state?.AdminReducer?.interest_list
  );

  const ageList = useSelector((state) => state?.AdminReducer?.age_list);
  const originList = useSelector((state) => state?.AdminReducer?.origin_list);

  const deleted_account_list = useSelector(
    (state) => state?.AdminReducer?.deleted_account_list
  );
  
  const number_per_user = useSelector(
    (state) => state?.AdminReducer?.number_per_user
  );



  console.log(
    number_per_user,
    "=============================================="
  );

  useEffect(() => {
    dispatch(getAnalytics());
  }, []);

  useEffect(() => {
    // const result = Object.keys(analyticsList).map((key) => analyticsList[key]);
    // const data = Object.keys(analyticsList).map((key) =>key );
    var result = [];
    var data = [];
    for (var i = 0; i < analyticsList.length; i++) {
      result.push(analyticsList[i].count);
      data.push(analyticsList[i]._id.game_geners);
    }

    setGameGenre(result);
    setDataGameG(data);

    // console.log( analyticsList," analyticsList?.gameGenre analyticsList?.gameGenre analyticsList?.gameGenre")

    //     setGameGenre(  analyticsList?.gameGenre.map(a => a?._id?.game_geners))

    //     setDataGameG( analyticsList?.gameGenre.map(a => a?.count))

    const CountData = Object.keys(countryList).map((key) => countryList[key]);
    const data1 = Object.keys(countryList).map((key) => key);
    const updateData = data1.map((obj) => {
      return obj === "undefined" ? "Not given" : obj;
    });
    setCountryGraph(CountData);
    setCountryData(updateData);

    const CountData2 = Object.keys(InterstList).map((key) => {
      return key != "undefined" && InterstList[key];

      //       if( key !='undefined'){
      // return  InterstList[key]
      //       }else{
      //         return  InterstList[key]
      //       }

      // return     ( key !='undefined' &&
      //        InterstList[key])
    });

    console.log(CountData2, "CountData2CountData2");

    const data2 = Object.keys(InterstList).map((key) => key);

    const updateD = data2.map((obj) => {
      return obj;
    });

    setInterestGraph(
      CountData2.filter(function (element) {
        return element !== false;
      })
    );
    setInterestData(
      updateD.filter(function (element) {
        return element !== "undefined";
      })
    );

    const ageCountData = Object.keys(ageList).map((key) => ageList[key]);
    const data3 = Object.keys(ageList).map((key) => key);
    setAgeGraph(ageCountData);
    setAgeData(data3);

    const originData = Object.keys(originList).map((key) => originList[key]);
    const data4 = Object.keys(originList).map((key) => key);
    setOriginGraphData(originData);
    setOriginData(data4);

    console.log(deleted_account_list,"788888888888888888888888888888888888888888888888888888888888")

    setdeletedGraphData(deleted_account_list.map((a) => a.count));
    console.log(deletedGraphData,"ffffffffffffffffffffffffffffffffffffffffffffffffffffffffd")

    // setnumberofuserList(number_per_user.map((a) => a._id));
    setnumberofuserList(number_per_user.map((a) => a.title));
    setnumberofuserGraphData(number_per_user.map((a) => a.userCount));

    console.log(numberofuserGraphData,numberofuserList,"fgggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg")

    setdeletedData(deleted_account_list.map((a) => a._id));
  }, [
    analyticsList,
    countryList,
    InterstList,
    ageList,
    originList,
    deleted_account_list,
  ]);

  console.log(dataGameG, dataGameG, "dataGameG");

  console.log(number_per_user,"ijok;liolppppppppppppppppppppppppppppppppppppppppppppppppp")

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Analytics</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartMixed" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Number of Users Per Game Genre</h4>
                      {/* <div className="widget-content searchable-container">
                        <button className="" id="btn-add-contact">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-download"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10"></polyline>
                            <line x1="12" y1="15" x2="12" y2="3"></line>
                          </svg>
                        </button>
                       
                      </div> */}
                    </div>
                  </div>
                  <ColumnChart dataGameG={dataGameG} gameGenre={gameGenre} />
                </div>
                <div className="widget-content widget-content-area">
                  <div id="mixed-chart3" className=""></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartMixed" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Number of Users Per Country</h4>
                    </div>
                  </div>
                  <PerCountryChart
                    countryGraph={countryGraph}
                    countryData={countryData}
                  />
                </div>
                <div className="widget-content widget-content-area">
                  <div id="mixed-chart2" className=""></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartBar" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Origin</h4>
                    </div>
                  </div>
                  <BarChart
                    originGraphData={originGraphData}
                    originData={originData}
                  />
                </div>
                <div className="widget-content widget-content-area">
                  <div id="s-bar" className=""></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartMixed" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Number of Users Per Interests</h4>
                    </div>
                  </div>
                  <InterestChart
                    interestGraph={interestGraph}
                    interestData={interestData}
                  />
                </div>
                <div className="widget-content widget-content-area">
                  <div id="mixed-chart" className=""></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartMixed" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Average Age</h4>
                    </div>
                  </div>
                  <AgeCountChart ageGraph={ageGraph} ageData={ageData} />
                </div>
                <div className="widget-content widget-content-area">
                  <div id="mixed-chart4" className=""></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartMixed" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Delete Accounts</h4>
                      {/* <div className="widget-content searchable-container">
                        <button className="" id="btn-add-contact">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-download"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path> 
                            <polyline points="7 10 12 15 17 10"></polyline>
                            <line x1="12" y1="15" x2="12" y2="3"></line>
                          </svg>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <Delete1
                    interestGraph={deletedGraphData}
                    interestData={deletedData}
                  />
                </div>
                <div className="widget-content widget-content-area">
                  <div id="mixed-chart4" className=""></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div id="chartMixed" className="col-xl-12 layout-spacing">
              <div className="statbox widget box box-shadow">
                <div className="widget-header">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                      <h4>Number of User Per Games</h4>
                      {/* <div className="widget-content searchable-container">
                        <button className="" id="btn-add-contact">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-download"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path> 
                            <polyline points="7 10 12 15 17 10"></polyline>
                            <line x1="12" y1="15" x2="12" y2="3"></line>
                          </svg>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <DeleteAccount
                    deletedaccount={numberofuserGraphData}
                    deletedData={numberofuserList}
                  />
                </div>
                {/* <div className="widget-content widget-content-area">
                  <div id="mixed-chart4" className=""></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default Analytics;
