import Api from "../../Server/ConfigApi";
import {
  ADVERTISE_LIST,
  BLOG_LIST,
  DASHBOARD_LIST,
  NOTIFICATION_LIST,
  PRIZE_LIST,
  QUEST_LIST,
  REPORT_LIST,
  VIEW_BLOG,
  WEEKLY_BOX,
  CHAT_USER_LIST,
  USER_CHAT,
  USER_CONVO_LIST,
  DELETED_ACC_LIST,
  VIEW_USER,
  USERS_CONVO,
  ANALYTIC_LIST,
  COUNTRY_LIST,
  INTEREST_LIST,
  AGE_AVERAGE_LIST,
  ORIGIN_LIST,
  INTEREST_DATA_LIST,
  DELETE_ACCOUNT_LIST,
  ADD_ADVER_SUCCESS,
  START_LOADING,
  STOP_LOADING,
  UPDATE_ADVER_SUCCESS,
  Game_LIST,
  POOL_LIST,
  number_per_user,
} from "../Constant";

import { toast } from "react-toastify";
import { Userlist } from "./LoginAction";
export const DashBoard = () => async (dispatch) => {
  const response = await Api.get("/Dashboard");
  // return response.data;
  if (response.data.status == 200) {
    dispatch({
      type: DASHBOARD_LIST,
      payload: response.data,
    });
  }
};

export const getConvo = (data) => async (dispatch) => {
  const response = await Api.post("/getConvo", data);
  if (response.data.status == 200) {
    dispatch({
      type: USERS_CONVO,
      payload: response.data.data,
    });
  }
  if (response.data.status == 400) {
    toast.info(response.data.message);
  }
};

export const getChatUserList = () => async (dispatch) => {
  const response = await Api.get("/getUserList");
  if (response.data.status == 200) {
    dispatch({
      type: CHAT_USER_LIST,
      payload: response.data.data,
    });
  }
  if (response.data.status == 400) {
    toast.info(response.data.message);
  }
};

export const getUserConvoList = (data) => async (dispatch) => {
  const response = await Api.post("/getUserChatList", data);
  if (response.data.status == 200) {
    dispatch({
      type: USER_CONVO_LIST,
      payload: response.data.data,
    });
  }
  if (response.data.status == 400) {
    toast.info(response.data.message);
  }
};

export const getChats = (roomId) => async (dispatch) => {
  const response = await Api.get(`/getConversation/${roomId}`);
  if (response.data.status == 200) {
    dispatch({
      type: USER_CHAT,
      payload: response.data.data,
    });
  }
  if (response.data.status == 400) {
    toast.info(response.data.message);
  }
};

export const dataWeeklyBox = () => async (dispatch) => {
  const response = await Api.get("/weeklyboxlist");
  if (response?.data) {
    dispatch({
      type: WEEKLY_BOX,
      payload: response.data.data,
    });
  }
};

export const addDataWeeklyBox = (data) => async (dispatch) => {
  const response = await Api.post("/AddWeeklybox", data);
  if (response.data.status == 200) {
    dispatch(dataWeeklyBox());
    toast.success(response?.data.message);
  }
  return response;
};

export const deleteDataWeeklyBox = (id) => async (dispatch) => {
  const response = await Api.get(`/Deleteweeklybox?id=${id}`);
  if (response?.data.status == 200) {
    dispatch(dataWeeklyBox());
    toast.success(response?.data.message);
  }
  return response;
};

export const getDataQuest = () => async (dispatch) => {
  const response = await Api.get("/GetQuest");
  if (response?.data) {
    dispatch({
      type: QUEST_LIST,
      payload: response.data.data,
    });
  }
};
export const addQuest = (data) => async (dispatch) => {
  const response = await Api.post("/AddQuest", data);
  if (response.data.status == 200) {
    toast.success(response?.data.message);
  }
  return response;
};
export const updateQuest = (data) => async (dispatch) => {
  const response = await Api.put("/quest", data);
  if (response.data.status == 200) {
    toast.success(response?.data.message);
  }
  return response;
};

export const deleteDataQuest = (id) => async (dispatch) => {
  const response = await Api.get(`/QuestDelete?id=${id}`);
  if (response?.data.status == 200) {
    dispatch(getDataQuest());
    toast.success(response?.data.message);
  }
  return response;
};

export const getBlogList = (data) => async (dispatch) => {
  const response = await Api.get("/Getblog");
  if (response?.data) {
    dispatch({
      type: BLOG_LIST,
      payload: response.data.data,
    });
  }
};

export const addBlogData = (data) => async (dispatch) => {
  const res = await Api.post("/Addblog", data);
  if (res?.data?.status == 200) {
    dispatch(getBlogList());
    toast.success(res?.data.message);
  }
  return res;
};

export const deleteBlogs = (id) => async (dispatch) => {
  const response = await Api.post(`/Deleteblog?id=${id}`);
  if (response?.data.status == 200) {
    dispatch(getBlogList());
    toast.success(response?.data.message);
  }
  return response;
};

export const viewBlogList = (id) => async (dispatch) => {
  const res = await Api.get(`/ViewBlog?id=${id}`);
  if (res?.data) {
    dispatch({
      type: VIEW_BLOG,
      payload: res.data.data,
    });
  }
};
export const updateBlog = (data) => async (dispatch) => {
  const response = await Api.post("/Updateblog", data);
  if (response?.data?.status == 200) {
    toast.success(response?.data.message);
  }
  return response;
};

export const getAdvertising = (data) => async (dispatch) => {
  const res = await Api.get("/AdvertisementList");
  if (res?.data) {
    dispatch({
      type: ADVERTISE_LIST,
      payload: res?.data.data,
    });
  }
};

export const addAdvertising = (data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
  const res = await Api.post("/Addadvertisement", data);
  console.log(res, "res");
  if (res.data.status == 200) {
    dispatch({ type: ADD_ADVER_SUCCESS, payload: res?.data?.data });
    toast.success(res?.data?.message);
    dispatch({ type: STOP_LOADING, payload: false });
  } else {
    toast.error(res?.data?.message);
    dispatch({ type: STOP_LOADING, payload: false });
  }
  return res;
};

export const deleteAdvertise = (id) => async (dispatch) => {
  const response = await Api.get(`/DeleteAdvertisement?id=${id}`);

  if (response.data.status == 200) {
    dispatch(getAdvertising());
    toast.success(response?.data.message);
  }
  return response;
};

export const stopAdvertise = (data) => async (dispatch) => {
  const response = await Api.post("/manageAdvertisement", data);
  console.log(response, "startStop");

  if (response.data.status == 200) {
    dispatch(getAdvertising());
    toast.success(response?.data.message);
  }
  return response;
};

export const updateAdvertisement = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: false });
  const response = await Api.post(`/UpdateAdvertisement?id=${id}`);
  if (response.data?.success) {
    dispatch({
      type: UPDATE_ADVER_SUCCESS,
      payload: response?.data?.data,
    });

    dispatch({ type: STOP_LOADING, payload: false });
  } else {
    toast.error(response?.data?.message);
    dispatch({ type: STOP_LOADING, payload: false });
  }
  return response;
};

export const getReportList = (data) => async (dispatch) => {
  const response = await Api.get("/Reportlist");
  if (response?.data) {
    dispatch({
      type: REPORT_LIST,
      payload: response.data.data,
    });
  }
};

export const closeReport = (report_id) => async (dispatch) => {
  const response = await Api.delete(`/closeReport?report_id=${report_id}`);
  if (response?.data?.status == 200) {
    dispatch(getReportList());
    toast.success(response?.data?.message);
  }
  return response;
};

export const getNotifications = (data) => async (dispatch) => {
  const response = await Api.get("/AdminNotificationList");
  if (response?.data) {
    dispatch({
      type: NOTIFICATION_LIST,
      payload: response.data.data,
    });
  }
};
export const addNotifications = (data) => async (dispatch) => {
  const res = await Api.post("/AdminNotification", data);
  console.log(res);
  if (res.data.status == 200) {
    dispatch(getNotifications());
    toast.success(res?.data?.message);
  }

  return res;
};

export const getPrizeDrawList = (data) => async (dispatch) => {
  const response = await Api.get("/GetPriceDrawList");
  if (response?.data) {
    dispatch({
      type: PRIZE_LIST,
      payload: response.data.data,
    });
  }
};
export const addPrizeDraw = (data) => async (dispatch) => {
  const res = await Api.get(
    `/AddpriceDraw?quest_id=${data.quest_id}&user_id=${data.user_id}`
  );
  console.log(res, "prize draw");
  // if(res.data.status==200){
  //    dispatch(get())
  // }
  return res;
};
export const getDeleteAccList = (data) => async (dispatch) => {
  const response = await Api.get("/DeletedAccountlist");
  if (response?.data) {
    dispatch({
      type: DELETED_ACC_LIST,
      payload: response.data.data,
    });
  }
};

export const viewUserDetail = (id) => async (dispatch) => {
  const res = await Api.get(`/user_detail?id=${id}`);
  if (res?.data) {
    dispatch({
      type: VIEW_USER,
      payload: res.data.data,
    });
  }
};
export const udpateUserDob = (obj) => async (dispatch) => {
  const res = await Api.put("/updateUserDob", obj);
  if (res?.data?.status == "200") {
    dispatch({
      type: VIEW_USER,
      payload: res.data.data,
    });
    toast.success(res?.data?.message);
  }
};

export const getAnalytics = () => async (dispatch) => {
  const response = await Api.get("/getAnalytics");
  console.log(
    response.data.data.user_per_games,
    "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
  );

  if (response?.data)
    dispatch({
      type: ANALYTIC_LIST,
      payload: response.data.data.gameGenre,
    });
  dispatch({
    type: COUNTRY_LIST,
    payload: response.data.data.country,
  });
  dispatch({
    type: INTEREST_LIST,
    payload: response.data.data.interest,
  });
  dispatch({
    type: AGE_AVERAGE_LIST,
    payload: response.data.data.age,
  });
  dispatch({
    type: ORIGIN_LIST,
    payload: response.data.data.origin,
  });
  dispatch({
    type: DELETE_ACCOUNT_LIST,
    payload: response.data.data.deleteuser,
  });
  dispatch({
    type: number_per_user,
    payload: response.data.data.user_per_games,
  });
};

export const getInterests = () => async (dispatch) => {
  const res = await Api.get("/GetInterstlist");
  // if (rse.data) {

  // }
  dispatch({
    type: INTEREST_DATA_LIST,
    payload: res.data.data,
  });
};

export const liveGoStatus = (data) => async (dispatch) => {
  const res = await Api.get(
    `/golive?id=${data.id}&status=${data.status}&live_date=${data.live_date}`
  );
  // console.log(res.data);
  if (res.data.status == 200) {
    dispatch(dataWeeklyBox());
    toast.success(res?.data.message);
  }
  return res;
};
export const reportDelete = (id) => async (dispatch) => {
  const response = await Api.delete(`/deleteReport?id=${id}`);
  if (response.data.status == 200) {
    dispatch(getReportList());
    toast.success(response?.data?.message);
  }
  return response;
};

export const DeleteAccount = (data) => async (dispatch) => {
  const res = await Api.post("/DeleteAccount", data);
  console.log(res);
  if (res.data.status == 200) {
    toast.success(res?.data?.message);
  }

  return res;
};

//V2 Action

export const GetGamelistActionV2 = (search) => async (dispatch) => {
  let url="/GetGamelist"

  if (search) {
    url += `?search=${search}`;
  }
  console.log(url)


  const res = await Api.post(url);

  if (res.data.status == 200) {
    dispatch({
      type: Game_LIST,
      payload: res.data.data,
    });

    // toast.success(res?.data?.message);
  }

  return res;
};

export const AddAndUpdategameActionV2 = (data) => async (dispatch) => {
  const res = await Api.post("/AddAndUpdategame", data);
  console.log(res);
  if (res.data.status == 200) {
    dispatch(GetGamelistActionV2());
    // toast.success(res?.data?.message);
  }

  return res;
};

export const DeleteGameActionV2 = (data) => async (dispatch) => {
  const res = await Api.post("/DeleteGame", data);
  console.log(res);
  if (res.data.status == 200) {
    dispatch(GetGamelistActionV2());
    // toast.success(res?.data?.message);
  }

  return res;
};

// add pool

export const addPool = (data) => async (dispatch) => {
  const res = await Api.post("/pool", data);
  console.log(res);
  if (res.data.status == 200) {
    // dispatch(());
    // dispatch({
    //   type: POOL_LIST,
    //   payload: res.data.data,
    // });
    toast.success(res?.data?.message);
  }

  return res;
};

// edit pool

export const editPool = (id, data) => async (dispatch) => {
  const res = await Api.put(`pool/${id}`, data);
  console.log(res, "res");
  if (res.data.status == 200) {
    // dispatch(());
    // dispatch({
    //   type: POOL_LIST,
    //   payload: res.data.data,
    // });
    toast.success(res?.data?.message);
  }

  return res;
};

// export const udpateUserDob = (obj) => async (dispatch) => {
//   const res = await Api.put("/updateUserDob", obj);
//   if (res?.data?.status == "200") {
//     dispatch({
//       type: VIEW_USER,
//       payload: res.data.data,
//     });
//     toast.success(res?.data?.message);
//   }
// };

// get pool

// export const getPool = async (data) => {
//   const res = await Api.get(
//     `/pool?search=${data}`
//   );
//   // console.log(res.data);
//   // if (res.data.status == 200) {
//   //   // dispatch(dataWeeklyBox());
//   //   // toast.success(res?.data.message);
//   // }
//   return res;
// };

export const getPool = (data) => async (dispatch) => {
  let url = "/pool";
  if (data?.search) {
    url += `?search=${data?.search}`;
  }
  try {
    var poollist = await Api.get(url);

    if (poollist.data.status == 200) {
      dispatch({
        type: POOL_LIST,
        payload: poollist.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

// delete pool

export const DeletePool = (data) => async (dispatch) => {
  const res = await Api.delete(`/pool/${data}`);
  console.log(res);
  if (res.data.status == 200) {
    dispatch(getPool({ search: "" }));
    toast.success(res?.data?.message);
  }

  return res;
};

// handle Subscription

export const handleSubscription = (data) => async (dispatch) => {
  const res = await Api.put(`/user-subscribe`, data);
  console.log(res);
  if (res.data.status == 200) {
    dispatch(Userlist());
    toast.success(res?.data?.message);
  }

  return res;
};

// dashboard time spent by user

export const avgtimeuser = (data) => async (dispatch) => {
  // Base URL for the API endpoint
  const baseUrl = '/admin-dashboard';
  
  // Initialize URLSearchParams for query parameters
  const queryParams = new URLSearchParams();
  
  // Check if `data` is present and append query parameters
  if (data) {
    if (data.activeUserFromDate || data?.startOfWeek ) {
      queryParams.append('active_start_date', data.activeUserFromDate || data?.startOfWeek);
    }
    if (data.activeUserToDate || data?.endOfWeek) {
      queryParams.append('active_end_date', data.activeUserToDate || data?.endOfWeek);
    }

    if(data.inactiveUserFromDate || data.inActivestartOfWeek){
      queryParams.append('inactive_start_date', data.inactiveUserFromDate || data.inActivestartOfWeek );
    }if (data.inactiveUserToDate || data?.inActiveendOfWeek) {
      queryParams.append('inactive_end_date', data.inactiveUserToDate || data?.inActiveendOfWeek);
    }

    if(data.avgUserFromDate || data.avgstartOfWeek){
      queryParams.append('avg_start_date', data.avgUserFromDate || data.avgstartOfWeek );
    }if (data.avgUserToDate || data?.avgendOfWeek) {
      queryParams.append('avg_end_date', data.avgUserToDate || data?.avgendOfWeek);
    }
  }

  // Build the final URL with or without query parameters
  const url = queryParams.toString() ? `${baseUrl}?${queryParams.toString()}` : baseUrl;

  try {
    // Make the API request
    const res = await Api.get(url);
    
    // Uncomment and customize the following lines based on your needs
    // if (res.data.status === 200) {
    //   dispatch(avgtimeuser()); // Adjust this dispatch if needed
    //   toast.success(res?.data?.message);
    // }

    return res;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error fetching data:", error);
    throw error; // Rethrow or handle the error based on your use case
  }
};


