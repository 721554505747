import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { getDeleteAccList } from "../../Redux/Action/AdminPannel";
import ReactPaginate from "react-paginate";
export default function DeletedAccountList() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const dispatch = useDispatch();
  const deletedAcc = useSelector((state) => state?.AdminReducer.delete_list);
  useEffect(() => {
    dispatch(getDeleteAccList());
  }, []);

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % deletedAcc.length);
  };
  useEffect(() => {
    if (page && deletedAcc) {
      setPageCount(Math.ceil(deletedAcc.length / page));
    }
  }, [deletedAcc, page]);

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Delete Account Requests</a>
              </li>
            </ol>
          </nav>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll_delete-acc-request">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deletedAcc
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((data) =>
                        data?.first_name?.toLowerCase().includes(search)
                      )
                      ?.map((item, i) => {
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>
                              {item?.first_name} {item?.sur_name}
                            </td>

                            <td>{item?.email}</td>
                            <td>{item?.reason ? item?.reason : "No reason"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
          activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>
    </Master>
  );
}
