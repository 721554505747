import React from 'react'
import { Outlet,Navigate } from 'react-router-dom'
const AdminPrivateRoute =()=>{
    const data= sessionStorage.getItem("accessToken")
    if(data){
        return <Outlet />
    }else{
        return <Navigate  to="/" />
    }
}
export default AdminPrivateRoute;