import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBlogs, getBlogList } from "../../Redux/Action/AdminPannel";
import Master from "../Include/Master";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import DeleteModal from "../DeleteModal/DeleteModal";
const Blogs = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogList = useSelector((state) => state?.AdminReducer?.blog_list);
  useEffect(() => {
    dispatch(getBlogList());
  }, []);

  const deleteBlogHandle = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
    // dispatch(deleteBlogs(id));
    // if (id) {
    //   dispatch(getBlogList());
    // }
  };

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % blogList.length);
  };
  useEffect(() => {
    if (page && blogList) {
      setPageCount(Math.ceil(blogList.length / page));
    }
  }, [blogList, page]);
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="blogs.html">Blogs</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        <div className="row layout-top-spacing mb-2">
          <div className="col-lg-3 col-md-3 col-sm-3 mb-4">
            <div className="position-relative searchbar_blog">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id="t-text"
                type="text"
                name="txt"
                placeholder="Search"
                className="form-control"
                required=""
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end me-4">
                <a
                  className=""
                  target="_blank"
                  id="btn-add-contact"
                  onClick={() => navigate("/blogs/create-blog")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </a>
              </div>
              {/* <!-- <div className="btn-group  mb-2 " role="group ml-2">
                    <button id="btndefault" type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                    <div className="dropdown-menu" aria-labelledby="btndefault">
                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-home-fill-1 mr-1"></i>Upcoming</a>
                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-gear-fill mr-1"></i>Present</a>
                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-bell-fill-2 mr-1"></i>Past</a>
                       
                    </div>
                </div> --> */}
            </div>
          </div>
        </div>
        <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"blog"}
        />
        <div className="row">
          {blogList
            ?.slice(resultOffset, resultOffset + page)
            ?.filter((item) => item?.post_title.toLowerCase().includes(search))
            ?.slice(0, page)
            .map((item, i) => {
              return (
                <div
                  className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4 position-relative"
                  key={i}
                >
                  <a
                    className="card style-2 mb-md-0 mb-4 blog_card"
                    onClick={() => navigate(`view-blog-page/${item?._id}`)}
                  >
                    <img
                      src={IMG_URL + "blogimage/" + item?.image}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body px-0 pb-0">
                      <h5 className="card-title mb-3">{item?.post_title}</h5>
                    </div>
                  </a>
                  <div className="dropdown d-inline-block position-absolute dotted_dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="elementDrodpown2"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-more-vertical"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="12" cy="5" r="1"></circle>
                        <circle cx="12" cy="19" r="1"></circle>
                      </svg>
                    </a>

                    <div
                      className="dropdown-menu right"
                      aria-labelledby="elementDrodpown2"
                      style={{
                        willChange: "transform",
                        position: "absolute",
                        transform: "translate3d(-141px, 19px, 0px)",
                        top: "0px",
                        left: "0px",
                      }}
                    >
                      <a
                        className="dropdown-item"
                        href="javascript:void(0);"
                        onClick={() => deleteBlogHandle(item?._id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                        Delete
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => navigate(`edit-blog/${item?._id}`)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>{" "}
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            key={page}
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"

            // className={
            //   pageCount === page
            //     ? "pagination active drk_btn"
            //     : "pagination"
            // }
          />

          {/* <ul className="pagination">
            <li className="prev">
              <a href="javascript:void(0);">Prev</a>
            </li>
            <li>
              <a href="javascript:void(0);">1</a>
            </li>
            <li className="active drk_btn">
              <a href="javascript:void(0);">2</a>
            </li>
            <li>
              <a href="javascript:void(0);">3</a>
            </li>
            <li className="next">
              <a href="javascript:void(0);">Next</a>
            </li>
          </ul> */}
        </div>
      </div>

      {/* <!-- Modal --> */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Blog
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="contact-name">
                      <input
                        type="text"
                        id=""
                        className="form-control"
                        placeholder="Title"
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      <input
                        type="file"
                        id=""
                        className="form-control"
                        placeholder="Upload Photo"
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      {/* <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="4"
                        className="form-control"
                      >
                        {" "}
                        Description
                      </textarea> */}
                      <span className="validation-text"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn drk_btn">
                Accept
              </button>
              <button
                className="btn btn btn-light-dark"
                data-bs-dismiss="modal"
              >
                <i className="flaticon-cancel-12"></i>Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default Blogs;
