import React, { useMemo } from "react";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import Moment from "../../Utils/MomentDate";
import Moment2 from "../../Utils/Moment";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  addDataWeeklyBox,
  dataWeeklyBox,
  getDataQuest,
  liveGoStatus,
} from "../../Redux/Action/AdminPannel";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { toast } from "react-toastify";
var today = new Date().toISOString().split("T")[0];
const Gamers = () => {
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [imageName, setImageName] = useState();
  const [preview, setPreview] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const [editWeekly, setWeeklyEdit] = useState(false);
  const [img1, setImg1] = useState();
  const [upadate, setUpdate] = useState("");
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("Filter");
  const [timeVal, setTimeVal] = useState();
  const [img, setImg] = useState();
  const listWeeklyBox = useSelector((state) => state?.AdminReducer?.weekly_box);
  const questList = useSelector((state) => state?.AdminReducer?.quest_list);
  const [slected, setSlected] = useState();
  const [showFilter, setFilter] = useState(false);

  const handleClose = () => setFilter(false);
  const handleShow = () => setFilter(true);

  const handleImageUpdate = (e) => {
    setImg(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    dispatch(dataWeeklyBox());
  }, []);

  useEffect(() => {
    setSlected(listWeeklyBox);
  }, [listWeeklyBox]);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Enter title";
    }
    if (!values.live_date) {
      errors.live_date = "Select date";
    }

    // if (!values.time) {
    //   errors.time = "Select time";
    // }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      live_date: "",
      id: "",
      quest_id: "",
    },
    validate,
    onSubmit: async (values, e) => {
      console.log(values,"values");
      let formData = new FormData();
      formData.append("weeklyboximage", img);
      formData.append("weeklyboxfront", img1);
      formData.append("title", values.title);
      formData.append("quest_id", values.quest_id);
      formData.append("live_date", values.live_date);
      formData.append("time", timeVal);
    formData.append("id", upadate);
      if (timeVal == " ") {
        toast.warning("Select time please");
      } else {
        dispatch(addDataWeeklyBox(formData)).then(function () {
          setShow(false);
          dispatch(dataWeeklyBox());
        });
      }
    },
  });

  const updateWeeklyBox = (value) => {
    setImageName(value?.image);
    setWeeklyEdit(true);
    setUpdate(value?._id);
    formik.setFieldValue("title", value.title);
    formik.setFieldValue("live_date", Moment2.Date(value.live_date));
    formik.setFieldValue("time", value?.time);
    formik.setFieldValue("weeklyboximage",value?.image);
    formik.setFieldValue("weeklyboxfront",value?.imagefront);
    formik.setFieldValue("quest_id",value?._id)
    setShow(true);
  };
 
  const [selectedFilter, setSelectedFilter] = useState({
    country: [],
    gender: [],
    gameGenre: [],
    interests: [],
  });
  const handleDelete = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const pastSelect = () => {
    const filteredPre = listWeeklyBox.filter(
      (dat) => new Date(dat?.live_date) < new Date()
    );
    setSlected(filteredPre);
  };
  const upcomingSelect = () => {
    const filteredPre = listWeeklyBox.filter(
      (dat) => new Date(dat?.live_date) > new Date()
    );
    setSlected(filteredPre);
  };
  const presentSelect = () => {
    const filteredPre = listWeeklyBox.filter(
      (dat) =>
        dat?.live_date.split("T")[0] == new Date().toISOString().split("T")[0]
    );
    setSlected(filteredPre);
  };
  const goLiveFilterHandle = () => {
    const filteredPre = listWeeklyBox.filter((dat) => dat?.status == 1);
    setSlected(filteredPre);
  };
  const draftFilterHandle = () => {
    const filteredPre = listWeeklyBox.filter((dat) => dat?.status == 0);
    setSlected(filteredPre);
  };

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % slected.length);
  };
  useEffect(() => {
    if (page && slected) {
      setPageCount(Math.ceil(slected.length / page));
    }
  }, [slected, page]);

  const handleClickLive = (id) => {
    dispatch(
      liveGoStatus({
        id: id,
        status: 1,
        live_date: new Date().toISOString().split("T")[0],
      })
    );
  };
  const handleClickDraft = (id) => {
    dispatch(
      liveGoStatus({
        id: id,
        status: 0,
        live_date: new Date().toISOString().split("T")[0],
      })
    );
  };

  useEffect(() => {
    dispatch(getDataQuest());
  }, []);

  const handleChange = (e) => {
   
    setTimeVal(moment(e.$d).format("HH:mm"));
  };
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Gamers</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}
    
        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end ">
                <button
                  className="me-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  onClick={() => {
                    setWeeklyEdit(false);
                    setShow(true);
                    setTimeVal("");
                    setImageName("");
                    setUpdate("")
                    formik.resetForm();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
                <button
                onClick={handleShow}
                id="btndefault"
                type="button"
                className="btn btn-outline-dark dropdown-toggle"
                data-bs-toggle="dropdown"
                // aria-haspopup="true"
                aria-expanded="false"
              >
                Filter
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>
              </div>
             
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Image</th>
                      <th>Gamername</th>
                      <th>Actions</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="height_m">
                    {slected
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((item) =>
                        item?.title.toLowerCase().includes(search)
                      )
                      ?.map((item, i) => {
                        return (
                          <tr>
                          <td>1</td>
                            <td>
                              <img
                                alt="avatar"
                                className="usr-img-frame me-2 rounded-circle"
                                src={IMG_URL + "weeklybox/" + item.image}
                              />
                            </td> 
                            <td>Gamername</td>
                            {/* <td>3 days</td> */}
                            
                         
                            <td className="custom_pd">
                              <a
                                onClick={() => updateWeeklyBox(item)}
                                // data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                className="delete_ml"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a>

                              <a onClick={() => handleDelete(item._id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={6}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>
      <Modal show={show} onHide={!show}>
        <form class="modal-content" onSubmit={formik.handleSubmit}>
          <div class="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {editWeekly ? "Edit Weekly Box" : "Add New Games"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(false);
                formik.resetForm();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div></div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <input                    
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Gamername"
                    />                  
                  </div>
                </div>
             
                <div className="col-md-12 mb-3">
                    <>                

                      <div className="contact-email">
                        <label>
                       Square Size (For Guilds, Events, Friendlists)
                        </label>
                        <input
                          onChange={(e) => setImg1(e.target.files[0])}
                          name="weeklyboxfront"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                          accept="image/*"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </>                
                </div>
                <div className="col-md-12 mb-3">
                    <>                

                      <div className="contact-email">
                        <label>
                        4x3 Image (For Profile)
                        </label>
                        <input
                          onChange={(e) => setImg1(e.target.files[0])}
                          name="weeklyboxfront"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                          accept="image/*"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </>                
                </div>

              
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={() => {
                setShow(false);
                formik.resetForm();
              }}
            >
              <i className="flaticon-cancel-12"></i> Discard
            </button>
            {formik.values.title && formik.values.live_date && timeVal ? (
              <button
                type="submit"
                className="btn drk_btn"
                data-bs-dismiss="modal"
                // onClick={() => setShow(false)}
              >
                Save
              </button>
            ) : (
              <button type="button" className="btn drk_btn">
                Save
              </button>
            )}
          </div>
        </form>
      </Modal>

      <Modal show={showFilter} onHide={handleClose}>
      <Modal.Header>
                  <Modal.Title>Filter</Modal.Title>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </Modal.Header>
                <Modal.Body>
                <a className="dropdown-item mb-4">
                    <i className="flaticon-bell-fill-2 mr-1"></i>
                    <div>
                    <h5>Games </h5>
                    <ul className="list-none p-0 filter_options  d-flex mb-0 flex-wrap">                         
                        <li className="mb-2 me-2"> Gamers Names 1 </li>
                        <li className="mb-2 me-2 "> Gamers Names 2</li>
                    </ul>
                    </div>
                </a>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-center align-items-center ">
                    <button
                      onClick={handleClose}
                      className="btn btn-light-dark"
                    >
                      Apply
                    </button>
                  </div>
                </Modal.Footer>

       
      </Modal>
    </Master>
  );
};

export default Gamers;
