import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/Components/assets/css/dark/loader.css";
import "../src/Components/assets/css/dark/loader.css";

import "../src/Components/assets/plugins/src/sweetalerts2/sweetalerts2.css";
import "../src/Components/assets/plugins/css/dark/editors/quill/quill.snow.css";
import "../src/Components/assets/css/dark/apps/mailbox.css";
import "../src/Components/assets/plugins/css/dark/sweetalerts2/custom-sweetalert.css";
import "../src/Components/assets/plugins/css/dark/editors/quill/quill.snow.css";
import "../src/Components/assets/css/dark/apps/mailbox.css";
import "../src/Components/assets/plugins/css/dark/sweetalerts2/custom-sweetalert.css";
import "../src/Components/assets/css/dark/scrollspyNav.css";
import "../src/Components/assets/css/dark/scrollspyNav.css";
import "../src/Components/assets/plugins/src/table/datatable/datatables.css";
import "../src/Components/assets/plugins/css/dark/table/datatable/dt-global_style.css";
import "../src/Components/assets/plugins/css/dark/table/datatable/dt-global_style.css";
import "../src/Components/assets/css/dark/dashboard/dash_1.css";
import "../src/Components/assets/css/dark/dashboard/dash_2.css";
import "../src/Components/assets/bootstrap/css/bootstrap.min-ooo.css";
import "../src/Components/assets/css/dark/main.css";
import "../src/Components/assets/bootstrap/css/bootstrap.min.css";
import "../src/Components/assets/css/dark/plugins.css";
import "../src/Components/assets/css/dark/plugins.css";
import "../src/Components/assets/css/dark/authentication/auth-cover.css";
import "../src/Components/assets/css/dark/authentication/auth-cover.css";
import "../src/Components/assets/plugins/css/dark/perfect-scrollbar/perfect-scrollbar.css";
import "../src/Components/assets/css/dark/custom.css";
import "../src/Components/assets/css/dark/custom.css";
// import "../src/Components/assets/plugins/src/highdark/styles/monokai-sublime.css";
import "../src/Components/assets/css/dark/components/modal.css";
import "../src/Components/assets/css/dark/apps/contacts.css";
import "../src/Components/assets/css/dark/components/modal.css";
import "../src/Components/assets/css/dark/apps/contacts.css";
// import "../src/Components/assets/plugins/src/gdarkbox/gdarkbox.min.css";
import "../src/Components/assets/css/dark/components/tabs.css";
import "../src/Components/assets/css/dark/components/tabs.css";
import "../src/Components/assets/plugins/src/notification/snackbar/snackbar.min.css";
import "../src/Components/assets/css/dark/elements/alert.css";
import "../src/Components/assets/css/dark/elements/color_library.css";
import "../src/Components/assets/css/dark/elements/custom-pagination.css";
import "../src/Components/assets/css/dark/elements/custom-tree_view.css";
import "../src/Components/assets/css/dark/elements/custom-typography.css";
import "../src/Components/assets/css/dark/elements/infobox.css";
import "../src/Components/assets/css/dark/elements/popover.css";
import "../src/Components/assets/css/dark/elements/search.css";
import "../src/Components/assets/css/dark/elements/tooltip.css";
import "../src/Components/assets/css/dark/forms/bootstrap-form.css";
import "../src/Components/assets/css/dark/forms/switches.css";
import "./Components/assets/css/dark/apps/chat.css";

import { Provider } from "react-redux";
import store from "./Redux/Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();
