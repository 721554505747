import React, { useMemo } from "react";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Modal, Toast } from "react-bootstrap";
import Moment from "../../Utils/MomentDate";
import Moment2 from "../../Utils/Moment";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  addDataWeeklyBox,
  dataWeeklyBox,
  getDataQuest,
  getPool,
  liveGoStatus,
} from "../../Redux/Action/AdminPannel";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { toast } from "react-toastify";
import FormSelect from "react-bootstrap/FormSelect";
import { Country, State, City } from "country-state-city";
import Form from "react-bootstrap/Form";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
var today = new Date().toISOString().split("T")[0];

const WeeklyBox = () => {
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [imageName, setImageName] = useState();
  const [preview, setPreview] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const [editWeekly, setWeeklyEdit] = useState(false);
  const [img1, setImg1] = useState();
  const [upadate, setUpdate] = useState("");
  const [pool, setpool] = useState("");
  const [country, setCountry] = useState("");
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("Filter");
  const [timeVal, setTimeVal] = useState();
  const [utcTime, setutcTime] = useState();
  const [utc, setUTC] = useState();
  const [liveDate, setLiveDate] = useState();
  const [localtime, setLocalTime] = useState();
  const [img, setImg] = useState();
  const listWeeklyBox = useSelector((state) => state?.AdminReducer?.weekly_box);
  const questList = useSelector((state) => state?.AdminReducer?.quest_list);
  const List = useSelector((state) => state?.AdminReducer?.pool_list);
  const [slected, setSlected] = useState();
  const [berlinTime,setBerlinTime] = useState();
  const handleImageUpdate = (e) => {
    setImg(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  // useEffect(() => {
  //   const now = new Date();

  //   if (utcTime && moment.isMoment(utcTime)) {
    
  //     // Convert timeVal to UTC
  //     const currentTimeUTC = utcTime.utc();
  //     setUTC(utcTime.utc().format("YYYY-MM-DD HH:mm"));
  //     // Output UTC time formatted as per your needs
  //   }

  //   // Get current time in UTC format
  //   // const currentTimeUTC = now.toUTCString();
  //   // console.log("Current time (UTC):", currentTimeUTC);

  //   // Get current time in local time zone
  //   const currentTimeLocal = now.toLocaleTimeString();
  //   setLocalTime(currentTimeLocal);
  // }, [timeVal, utcTime]);


  console.log(utc,"===============================================================")

  useEffect(() => {
    dispatch(dataWeeklyBox());
  }, []);

  useEffect(() => {
    dispatch(getPool({ search: "" }));
  }, []);

  useEffect(() => {
    setSlected(listWeeklyBox);
  }, [listWeeklyBox]);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Enter title";
    }
    if (!values.live_date) {
      errors.live_date = "Select date";
    }

    // if (!values.time) {
    //   errors.time = "Select time";
    // }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      live_date: "",
      id: "",
      quest_id: "",
    },
    validate,
    onSubmit: async (values, e) => {
      console.log(values, "values");


    
      let formData = new FormData();
      formData.append("weeklyboximage", img);
      formData.append("weeklyboxfront", img1);
      formData.append("title", values.title);
      formData.append("quest_id", values.quest_id);
      formData.append("live_date", values.live_date);
      formData.append("time", timeVal);
      formData.append("date_utc", utc);
      formData.append("german_time", berlinTime);
      if (editWeekly) {
        formData.append("id", upadate);
      }

      formData.append("country", country);
      formData.append("pool_id", pool);
      if (timeVal == " ") {
        toast.warning("Select time please");
      } else if (!pool) {
        toast.error("Please select the pool");
      } else if (pool == "Select Pool") {
        toast.error("Please select the pool");
      } else {
        dispatch(addDataWeeklyBox(formData)).then(function () {
          setShow(false);
          setCountry("");
          setpool("");
          dispatch(dataWeeklyBox());
        });
      }
    },
  });

  console.log(timeVal, "time");

  const updateWeeklyBox = (value) => {
    console.log(value?.pool_id?._id, "==================================");

    // const utcDateString = value?.date_utc
    // const utcMoment = moment.utc(utcDateString, 'YYYY-MM-DD HH:mm aa');

    const germanTimeString = value?.german_time

    const localMoment = moment(germanTimeString, 'YYYY-MM-DD HH:mm')
    // const localMoment = utcMoment.local();

    

    setImageName(value?.image);
    setWeeklyEdit(true);
    setUpdate(value?._id);
    formik.setFieldValue("title", value.title);
    // formik.setFieldValue("live_date", Moment2.Date(value.live_date));
    formik.setFieldValue("live_date", localMoment.format('YYYY-MM-DD'));
    formik.setFieldValue("time", value?.time);
    formik.setFieldValue("weeklyboximage", value?.image);
    formik.setFieldValue("weeklyboxfront", value?.imagefront);
    formik.setFieldValue("quest_id", value.quest_id._id);
    // formik.setFieldValue("quest_id", value?._id);

    setCountry(value?.country);
    setpool(value?.pool_id?._id);
    setShow(true);
  };

  const handleDelete = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const pastSelect = () => {
    const filteredPre = listWeeklyBox.filter(
      (dat) => new Date(dat?.live_date) < new Date()
    );
    setSlected(filteredPre);
  };
  const upcomingSelect = () => {
    const filteredPre = listWeeklyBox.filter(
      (dat) => new Date(dat?.live_date) > new Date()
    );
    setSlected(filteredPre);
  };
  const presentSelect = () => {
    const filteredPre = listWeeklyBox.filter(
      (dat) =>
        dat?.live_date.split("T")[0] == new Date().toISOString().split("T")[0]
    );
    setSlected(filteredPre);
  };
  const goLiveFilterHandle = () => {
    const filteredPre = listWeeklyBox.filter((dat) => dat?.status == 1);
    setSlected(filteredPre);
  };
  const draftFilterHandle = () => {
    const filteredPre = listWeeklyBox.filter((dat) => dat?.status == 0);
    setSlected(filteredPre);
  };

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % slected.length);
  };
  useEffect(() => {
    if (page && slected) {
      setPageCount(Math.ceil(slected.length / page));
    }
  }, [slected, page]);

  const handleClickLive = (id) => {
    dispatch(
      liveGoStatus({
        id: id,
        status: 1,
        live_date: new Date().toISOString().split("T")[0],
      })
    );
  };

  const handleClickDraft = (id) => {
    dispatch(
      liveGoStatus({
        id: id,
        status: 0,
        live_date: new Date().toISOString().split("T")[0],
      })
    );
  };

  useEffect(() => {
    dispatch(getDataQuest());
  }, []);

  const handleChange = (e) => {
    console.log(e<=Date.now(), "eeeeeeeeeeeeeee");

    // if(e<=Date.now()){
    //   toast.error("Please select future time")
    // }else{
      // Set the time value with AM/PM formatting
    setTimeVal(moment(e.$d).format("hh:mm A"));
  
    // Set UTC time as a moment object
    setutcTime(moment(e.$d));
  
    // Convert the selected time to UTC based on the selected date
    const date = new Date(liveDate);
    const time = new Date(e.$d);
  
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
  
    // Convert to UTC and format with AM/PM
    const utcDate = new Date(date.toISOString());
    console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuuu", moment(utcDate).format("YYYY-MM-DD HH:mm"));
     setBerlinTime(moment(utcDate).format("YYYY-MM-DD HH:mm"));


    // const berlinTime = new Intl.DateTimeFormat('en-GB', {
    //   timeZone: 'Europe/Berlin',
    //   dateStyle: 'full',  // You can adjust this based on your formatting needs
    //   timeStyle: 'long'   // You can adjust this based on your formatting needs
    // }).format(utcDate);
    
    // console.log(berlinTime,"berlintime");
    setBerlinTime(berlinTime.format("YYYY-MM-DD HH:mm"))
  
    // Format UTC date with day, month, year, and 12-hour time with AM/PM
    const formattedUTC = moment(utcDate).utc().format("YYYY-MM-DD HH:mm");
    console.log("ooooooooooooooooooooo", formattedUTC);
  
    // Set the formatted UTC date
    setUTC(formattedUTC);

    // }

    
  
    
  };

  console.log(berlinTime,"bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb")
  


  console.log(utc,"ddaattteee")
  console.log(liveDate,"liveDate")

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Weekly Box</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}
        <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"weeklyBox"}
        />
        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end me-4">
                <button
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  onClick={() => {
                    setWeeklyEdit(false);
                    setShow(true);
                    setTimeVal("");
                    setImageName("");
                    setUpdate("");
                    formik.resetForm();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
              </div>
              <div className="btn-group  mb-2 " role="group ml-2">
                <button
                  id="btndefault"
                  type="button"
                  className="btn btn-outline-dark dropdown-toggle filter_btn"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {filterName}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </button>
                <div className="dropdown-menu" aria-labelledby="btndefault">
                  <a
                    onClick={() => {
                      upcomingSelect();
                      setFilterName("Upcoming");
                    }}
                    className="dropdown-item"
                  >
                    Upcoming
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      presentSelect();
                      setFilterName("Present");
                    }}
                  >
                    Present
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      pastSelect();
                      setFilterName("Past");
                    }}
                  >
                    Past
                  </a>
                  <a
                    onClick={() => {
                      draftFilterHandle();
                      setFilterName("Draft");
                    }}
                    className="dropdown-item"
                  >
                    Draft
                  </a>
                  <a
                    onClick={() => {
                      goLiveFilterHandle();
                      setFilterName("Go Live");
                    }}
                    className="dropdown-item"
                  >
                    Go Live
                  </a>
                </div>

                {/* 
                <select
                 className="btn  dropdown-toggle"
                    id="select-beast"
                    placeholder="Validity"
                    autocomplete="off"
                    value={selectedPresent}
          onChange={handleBrandChange}
                  >
                  
                    <option value="" selected>
                      Filter
                    </option>
                    <option value="Upcoming" className="dropdown-item"> Upcoming</option>
                    <option value="Present" className="dropdown-item">Present</option>
                    <option value="Past" className="dropdown-item"> Past</option>
                    <option value="Draft" className="dropdown-item">Draft</option>
                    <option value="Go Live" className="dropdown-item">Go Live</option>

                  </select> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Dashboard Image</th>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Created at</th>
                      {/* <th>Validity</th> */}
                      <th>Live by date</th>
                      <th>Live by time</th>
                      <th>Quest Title</th>
                      <th>Actions</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="height_m">
                    {slected
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((item) =>
                        item?.title.toLowerCase().includes(search)
                      )
                      ?.map((item, i) => {
                        {/* console.log(moment(item?.date_utc,'DD/MM/YYYY HH:mm').local().toDate(), "moment(item?.date_utc).toDate()");
                        console.log( moment.utc(item?.date_utc, "HH:mm").local().format("HH:mm")); */}

                        console.log(item?.date_utc,"utcDateString")

                        {/* const utcDateString = item?.german_time */}
                        const germanTimeString = item?.german_time

                        const localMoment = moment(germanTimeString, 'YYYY-MM-DD HH:mm')
                        {/* const utcMoment = moment.utc(utcDateString, 'YYYY-MM-DD HH:mm aa'); */}
                        {/* const localMoment = utcMoment.local(); */}
                        console.log('Local Date and Time:', localMoment.format('YYYY-MM-DD HH:mm aa'));
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>
                              <img
                                alt="avatar"
                                className="usr-img-frame me-2 rounded-circle"
                                src={IMG_URL + "weeklybox/" + item.image}
                              />
                            </td>
                            <td>
                              <img
                                alt="avatar"
                                className="usr-img-frame me-2 rounded-circle"
                                src={IMG_URL + "weeklybox/" + item.imagefront}
                              />
                            </td>
                            <td>
                              <a
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                href="#"
                              >
                                {item?.title}
                              </a>
                            </td>
                            <td>{Moment.Date(item?.createdAt)}</td>
                            {/* <td>3 days</td> */}
                            <td>{localMoment.format('DD/MM/YYYY')}</td>
                            <td>{localMoment.format("HH:mm")}</td>
                            <td>{item?.quest_id?.title}</td>
                            <td className="custom_pd">
                              <a
                                onClick={() => updateWeeklyBox(item)}
                                // data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                className="delete_ml"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a>

                              <a onClick={() => handleDelete(item._id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                            <td>
                              <div
                                className="btn-group  mb-2 me-4"
                                role="group"
                              >
                                <button
                                  id="btndefault1"
                                  type="button"
                                  className="btn  dropdown-toggle btn-light-dark _effect--ripple waves-effect waves-light"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {item.status == 1 ? "Live" : "Draft"}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="feather feather-chevron-down"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="btndefault1"
                                >
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                    onClick={() => handleClickDraft(item._id)}
                                  >
                                    <i className="flaticon-home-fill-1 mr-1"></i>
                                    Draft
                                  </a>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                    onClick={() => handleClickLive(item._id)}
                                  >
                                    <i className="flaticon-gear-fill mr-1"></i>
                                    Go Live
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={6}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>
      <Modal show={show} onHide={!show}>
        <form class="modal-content" onSubmit={formik.handleSubmit}>
          <div class="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {editWeekly ? "Edit Weekly Box" : "Add New Weekly Box"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(false);
                formik.resetForm();
                setpool("");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div></div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12 mb-3">
                  {/* <Form.Select
                  name="country"
                  value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                   <option>Select Country</option>
                  {Country.getAllCountries()?.map((country)=>{
                    return (
                      <option value={country?.name}>{country?.name}</option>
                    )
                  })}
                   
                   
                  </Form.Select> */}
                </div>

                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Title"
                    />

                    <span className="validation-text">
                      {!formik.values.title && formik.errors.title ? (
                        <p style={{ color: "red" }}>{formik.errors.title}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <select
                      id=""
                      class="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.quest_id}
                      name="quest_id"
                    >
                      <option value="" selected>
                        Select Quest
                      </option>
                      {questList?.map((item, i) => {
                        return <option value={item?._id}>{item?.title}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  {editWeekly ? (
                    <>
                      <div className="contact-email small-popup-img-upload">
                        <input
                          onChange={handleImageUpdate}
                          name="weeklyboximage"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                        />

                        <div className="view-small-upload">
                          {preview ? (
                            <img
                              className="view-small-upload"
                              alt="preview"
                              src={preview}
                            />
                          ) : (
                            <img
                              className="view-small-upload"
                              alt="img"
                              src={IMG_URL + "weeklybox/" + imageName}
                            />
                          )}
                        </div>
                        <span className="validation-text"></span>
                      </div>

                      <div className="contact-email">
                        <label>
                          Dashboard image(Image size should in 16*9 size)
                        </label>
                        <input
                          onChange={(e) => setImg1(e.target.files[0])}
                          name="weeklyboxfront"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                          accept="image/*"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="contact-email">
                        <label>
                          Dashboard image(Image size should in 16*9 size)
                        </label>
                        <input
                          onChange={(e) => setImg(e.target.files[0])}
                          name="weeklyboximage"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                          accept="image/*"
                        />

                        <span className="validation-text"></span>
                      </div>

                      <div className="contact-email">
                        <label>
                          Detail image(Image size should in 9*16 size)
                        </label>
                        <input
                          onChange={(e) => setImg1(e.target.files[0])}
                          name="weeklyboxfront"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                          accept="image/*"
                        />

                        <span className="validation-text"></span>
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="contact-email">
                      <label>
                        <strong>Live by date</strong>
                      </label>
                      <input
                        onChange={(e)=>{
                          setLiveDate(e.target.value)
                          formik.setFieldValue("live_date",e.target.value)
                        }}
                        value={formik.values.live_date}
                        min={today}
                        name="live_date"
                        type="date"
                        id=""
                        className="form-control"
                        placeholder="Live by Date"
                      />
                      <span className="validation-text">
                        {!formik.values.live_date && formik.errors.live_date ? (
                          <p style={{ color: "red" }}>
                            {formik.errors.live_date}*
                          </p>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="contact-email">
                      <label>
                        <strong>Select time</strong>
                      </label>
                      {/* <input
                        onChange={formik.handleChange}
                        value={formik.values.time}
                        name="time"
                        type="time"
                        id=""
                        className="form-control"
                        placeholder="Live by Date"
                      /> */}

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={timeVal}
                          onChange={(e) => handleChange(e)}
                          className="colorChange"
                        />
                      </LocalizationProvider>

                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="contact-email mt-3">
                      <label>
                        <strong>Select Pool</strong>
                      </label>
                      <Form.Select
                        name="pool_id"
                        value={pool}
                        onChange={(e) => {
                          setpool(e.target.value);
                        }}
                      >
                        <option>
                          <strong value="select">Select Pool</strong>
                        </option>
                        {List[0]?.map((pool) => {
                          return (
                            <option value={pool?._id}>{pool?.title}</option>
                          );
                        })}

                        {/* <option>Pool 2</option>
                  <option>Pool 3</option>
                  <option>Pool 4</option>
                  <option>Pool 5</option> */}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={() => {
                setShow(false);
                formik.resetForm();
                setpool("");
              }}
            >
              <i className="flaticon-cancel-12"></i> Discard
            </button>
            {formik.values.title && formik.values.live_date && timeVal ? (
              <button
                type="submit"
                className="btn drk_btn"
                data-bs-dismiss="modal"
                // onClick={() => setShow(false)}
              >
                Save
              </button>
            ) : (
              <button type="button" className="btn drk_btn">
                Save
              </button>
            )}
          </div>
        </form>
      </Modal>
    </Master>
  );
};

export default WeeklyBox;
