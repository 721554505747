import moment from "moment";

const Moment2={
    Date:(date)=>{
        if(date){
            return moment(date).format("YYYY-MM-DD")
        }

    }
}
export default Moment2;