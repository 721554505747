import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Master from "../../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogList,
  updateBlog,
  viewBlogList,
} from "../../../Redux/Action/AdminPannel";
import { useFormik } from "formik";
import RTextAditor from "../editBlog/RTextAditor";
const EditBlog = () => {
  const [img, setImg] = useState();
  const [htmlContent, setHtmlContent] = useState();
  const [givenContent, setGivenContent] = useState();
  const [preview, setPreview] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;

  const viewBlog = useSelector((state) => state?.AdminReducer?.view_blog);

  useEffect(() => {
    dispatch(viewBlogList(id));
  }, []);
  const dataHtmlHandle = (html) => {
    setHtmlContent(html);
  };
  useEffect(() => {
    if (viewBlog?._id == id) {
      setHtmlContent(viewBlog?.content);
      setGivenContent(viewBlog?.content);
      setImg(viewBlog?.image);
      formik.values.post_title = viewBlog?.post_title;
      formik.values.content = viewBlog?.htmlContent;
    }
  }, [viewBlog]);

  const handleImgChange = (e) => {
    setImg(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const validate = (values) => {
    const errors = {};
    if (!values.post_title) {
      errors.post_title = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      post_title: "",
      content: "",
      blog: "",
      id: "",
    },
    validate,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("blog", img);
      formData.append("post_title", values.post_title);
      formData.append("content", htmlContent);
      formData.append("id", id);
      try {
        await dispatch(updateBlog(formData)).then(navigate("/blogs"));
        dispatch(getBlogList());
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="blogs.html ">Blogs</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Blog
              </li>
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        {/* <!-- <div className="row layout-top-spacing mb-2">
                        <div className="col-lg-3 col-md-3 col-sm-3 mb-4">
                            <input id="t-text" type="text" name="txt" placeholder="Search" className="form-control" required="">
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 text-sm-right text-center align-self-center">
                            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
                                <div className=" d-flex justify-content-end me-4">
                                    <button className="" data-bs-toggle="modal" data-bs-target="#exampleModal" id="btn-add-contact">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    </button>
                                </div>
                                <div className="btn-group  mb-2 " role="group ml-2">
                                    <button id="btndefault" type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                                    <div className="dropdown-menu" aria-labelledby="btndefault">
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-home-fill-1 mr-1"></i>Upcoming</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-gear-fill mr-1"></i>Present</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-bell-fill-2 mr-1"></i>Past</a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> --> */}

        <form
          className="row mb-4 layout-spacing layout-top-spacing"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="widget-content widget-content-area blog-create-section">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <input
                    onChange={formik.handleChange}
                    name="post_title"
                    type="text"
                    className="form-control"
                    id="post-title"
                    placeholder="Post Title"
                    value={formik.values.post_title}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-sm-12">
                  <label>Content</label>
                  <div id="blog-description">
                    {givenContent && (
                      <RTextAditor
                        htmlHandle={dataHtmlHandle}
                        givenContent={givenContent}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-xxl-0 mt-4">
            <div className="widget-content widget-content-area blog-create-section">
              <div className="row">
                {/* <div className="col-xxl-12 mb-4">
                                        <div className="switch form-switch-custom switch-inline form-switch-primary">
                                            <input className="switch-input" type="checkbox" role="switch" id="showPublicly" checked />
                                            <label className="switch-label" for="showPublicly">Publish</label>
                                        </div>
                                    </div>
                                    <div className="col-xxl-12 mb-4">
                                        <div className="switch form-switch-custom switch-inline form-switch-primary">
                                            <input className="switch-input" type="checkbox" role="switch" id="enableComment" checked />
                                            <label className="switch-label" for="enableComment">Enable Comments</label>
                                        </div>
                                    </div>
                                    <div className="col-xxl-12 col-md-12 mb-4">
                                        <label for="tags">Tags</label>
                                        <input id="tags" className="blog-tags" value="Lorem 1, Lorem 2, Lorem 3" />
                                    </div>
                                    <div className="col-xxl-12 col-md-12 mb-4">
                                        <label for="category">Category</label>
                                        <input id="category" name="category" value="Lorem 1, Lorem 2, Lorem 3, ipsum 4, Lorem ipsum" placeholder="Choose..." />
                                    </div> */}
                <div className="col-xxl-12 col-md-12 mb-4">
                  <label for="product-images">Featured Image</label>

                  <div className="multiple-file-upload upload-img-box">
                    <input
                      // onChange={(e) => setImg(e.target.files[0])}
                      onChange={handleImgChange}
                      type="file"
                      className="filepond file-upload-multiple"
                      name="filepond"
                      id="product-images"
                      multiple
                      data-allow-reorder="true"
                      data-max-file-size="3MB"
                      data-max-files="5"
                    />

                    {/* <div style={{ marginTop:"10px" }}>
                    {
                      !img?.name? img : img?.name
                    }
                    </div> */}
                  </div>
                  <div className="view-img-show">
                    <div className="plus-icon-show">
                      {preview ? (
                        <img alt="preview" src={preview} />
                      ) : (
                        <img
                          alt="blogImage"
                          src={IMG_URL + "blogimage/" + img}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xxl-12 col-sm-4 col-12 mx-auto">
                  <button className="btn stru w-100 drk_btn" type="submit">
                    Update Blog
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <!-- Modal --> */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Blog
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="contact-name">
                      <input
                        type="text"
                        id=""
                        className="form-control"
                        placeholder="Title"
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      <input
                        type="file"
                        id=""
                        className="form-control"
                        placeholder="Upload Photo"
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      {/* <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="4"
                        className="form-control"
                      >
                        {" "}
                        Description
                      </textarea> */}
                      <span className="validation-text"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn drk_btn">
                Accept
              </button>
              <button
                className="btn btn btn-light-dark"
                data-bs-dismiss="modal"
              >
                <i className="flaticon-cancel-12"></i>Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default EditBlog;
