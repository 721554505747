import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSupportData, Writeemail } from "../../Redux/Action/LoginAction";
import Master from "../Include/Master";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-bootstrap/Modal";
import { EditorState } from "draft-js";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
export default function Support() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [value, setValue] = useState("");
  const [email, setemail] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const [show, setShow] = useState(false);
  const [suportId, setSuportId] = useState();
  const [messageView, setMessageView] = useState(false);
  const [MessageData, setMessageData] = useState("");

  const [list, setList] = useState();
  const handleClose = () => setShow(false);
  const [subject, setSubject] = useState("");
  const handleShow = (email) => {
    setShow(true);
    setemail(email);
  };
  const handleMsgShowClose = () => {
    setMessageView(false);
  };

  const handleClickMessage = (item) => {
    setMessageData(item);
    // setShow(true);
    setMessageView(true);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetSupportData());
  }, []);
  const support_list = useSelector((state) => state.Loginreduce.support_list);
  var values = {};
  const handleCloseSupport = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const submitform = () => {
    if (value == "") {
      setShow(true);
    } else {
      values.subject = subject;
      values.content = value;
      values.email = email;
      values.type = "support_reply";
      values._id = suportId;
      setShow(false);
      dispatch(Writeemail(values)).then(function () {
        setShow(false);
        setValue(" ");
      });
    }
  };
  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % support_list.length);
  };
  useEffect(() => {
    if (page && support_list) {
      setPageCount(Math.ceil(support_list.length / page));
    }
  }, [support_list, page]);

  useEffect(() => {
    const data = support_list.filter((item, i) => item.user_id != null);
    setList(data);
  }, [support_list]);
  useEffect(() => {
    if (page && list) {
      setPageCount(Math.ceil(list.length / page));
    }
  }, [list, page]);

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Support</a>
              </li>
            </ol>
          </nav>
        </div>

        <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"support"}
        />
        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Gamer Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      {/* <th>Phone Number</th> */}
                      <th>Reason</th>
                      <th>Custom Message</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((item) =>
                        item?.user_id?.first_name.toLowerCase().includes(search)
                      )
                      ?.map(function (support_obj, i) {
                        console.log(support_obj);
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>
                              {/* {support_obj?.user_id?.first_name +
                                " " +
                                support_obj?.user_id?.sur_name} */}
                              {support_obj?.user_id?.gamer_name}
                            </td>

                            <td> {support_obj?.user_id?.email}</td>
                            <td>{support_obj?.user_id?.country}</td>
                            {/* <td>
                              {support_obj?.user_id?.phone_number &&
                                support_obj?.user_id?.country_code +
                                  " " +
                                  support_obj?.user_id?.phone_number}
                            </td> */}
                            <td>{support_obj.title}</td>
                            <td>
                              <p className="line_break">
                                {support_obj.description}
                              </p>
                            </td>
                            <td>
                              {support_obj?.status == 0 ? (
                                <>
                                  <button
                                    className="btn btn-light-dark"
                                    onClick={() =>
                                      handleCloseSupport(support_obj._id)
                                    }
                                  >
                                    Close
                                  </button>
                                  {support_obj &&
                                  Object.keys(
                                    support_obj?.support_reply ||
                                      support_obj?.support_reply == null
                                  ).length > 0 ? (
                                    <button
                                      className="btn drk_btn ms-2"
                                      onClick={() =>
                                        handleClickMessage(
                                          support_obj?.support_reply
                                        )
                                      }
                                    >
                                      View
                                    </button>
                                  ) : (
                                    <button
                                      className="btn drk_btn ms-2"
                                      onClick={() => {
                                        handleShow(support_obj?.user_id?.email);
                                        setSuportId(support_obj?._id);
                                      }}
                                    >
                                      Reply
                                    </button>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="paginating-container pagination-solid">
            <ReactPaginate
              activeClassName="active"
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Reply
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleClose();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="row layout-spacing layout-top-spacing">
                <input
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  name="post_title"
                  type="text"
                  className="form-control"
                  id="post-title"
                  placeholder="Subject"
                />
                <ReactQuill
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ indent: "-1" }, { indent: "+1" }],

                      [{ color: [] }],
                    ],
                  }}
                  value={value}
                  onChange={setValue}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn drk_btn"
                  onClick={() => {
                    submitform();
                  }}
                >
                  Reply
                </button>
                <button
                  type="button"
                  className="btn btn btn-light-dark"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal show={messageView} onHide={handleMsgShowClose}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Reply
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleMsgShowClose();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="row layout-spacing layout-top-spacing">
                <input
                  value={MessageData?.subject}
                  name="post_title"
                  type="text"
                  className="form-control"
                  id="post-title"
                  placeholder="Subject"
                />

                <ReactQuill
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ indent: "-1" }, { indent: "+1" }],

                      [{ color: [] }],
                    ],
                  }}
                  value={MessageData?.content}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn btn-light-dark"
                  onClick={() => {
                    handleMsgShowClose();
                  }}
                >
                  Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Master>
  );
}
