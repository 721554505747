import React from 'react'
import Master from '../Include/Master'
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useState } from 'react';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { AddAndUpdategameActionV2, GetGamelistActionV2 } from '../../Redux/Action/AdminPannel';
import { useEffect } from 'react';
import DeleteModal from '../DeleteModal/DeleteModal';
export default function Gamelist() {
    const [show, setShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [search,setSearch] = useState(null)
    const [delId, setdelId] = useState("");
    const [id, setId] = useState("");
    const dispatch=useDispatch();
    const formik = useFormik({
        initialValues: {
          title: "",
          icon: "",
          game_profile:""
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            icon: Yup.string(),
            game_profile:Yup.string(),
          }),

        onSubmit: async (values) => {
          try {
            let fordata=new FormData();
            fordata.append('id',id)
            fordata.append('title',values.title)
            fordata.append('gameicon',values.icon)
            fordata.append('game_profile',values.game_profile)

          dispatch(AddAndUpdategameActionV2(fordata))
          setShow(false);
          } catch (error) {
            console.log(error);
          }
        },
      });


    


      useEffect(() => {
       dispatch(GetGamelistActionV2(search))
      
      }, [search]);


      const Adminlist = useSelector((state) => state.AdminReducer.game_list);

      const updategame = (value) => {
       
        setId(value?._id);
        formik.setFieldValue("title", value.title);
        setShow(true);
      };




  return (
    <Master>
    <div className="middle-content container-xxl p-0">
      {/* <!-- BREADCRUMB --> */}
      <div className="page-meta">
        <nav className="breadcrumb-style-one" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Games</a>
            </li>
            {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
          </ol>
        </nav>
      </div>

      {/* <!-- Add Button Modal --> */}

      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
          <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
            <div className=" d-flex justify-content-end">
              <button
                className=""
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                id="btn-add-contact"
                onClick={() => {
                  setShow(true);
                  formik.resetForm();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-plus"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content widget-content-area br-8">
            <div
              id="zero-config_filter"
              className="dataTables_filter searchbar "
            >
              <div className="position-relative mb-4">
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="search"
                  className="form-control"
                  placeholder="Search"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </div>
            </div>
            <div className="table-responsive custom_scroll_notification">
              <table
                id="zero-config"
                className="table table-striped dt-table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Icon</th>
                    <th>Image</th>
                     
                    <th>Title</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        Adminlist?.map(function(dataobject,i){
                            return (
                                <tr key={i}>
                                <td>
                                    {i+1}
                                    </td>
                                    <td>
                              <img
                                alt="avatar"
                                className="usr-img-frame me-2 rounded-circle"
                                src={process.env.REACT_APP_IMAGE_URL + "gameicon/" + dataobject.gameicon}
                              />
                            </td> 
                            <td>
                              <img
                                alt="avatar"
                                className="usr-img-frame me-2 rounded-circle"
                                src={process.env.REACT_APP_IMAGE_URL + "gameicon/" + dataobject?.game_profile}
                              />
                            </td> 
                                    <td>
                                    {dataobject.title}
                                    </td>
                                    <td className="custom_pd">
                              <a
                                onClick={() => updategame(dataobject)}
                                // data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                className="delete_ml"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a>

                              <a onClick={() => {
                                setdelId(dataobject._id)
                              setDeleteShow(true)}}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
             
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="paginating-container pagination-solid">
    
      </div>
    </div>



    <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"Game"}
        />

    {/* <!-- Modal --> */}

 <Modal show={show} onHide={!show}>
        <form className="modal-content" onSubmit={formik.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Add Game
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
             
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Title"
                    />
                    <span className="validation-text">
                      {formik.errors.title ? (
                        <p style={{ color: "red" }}>{formik.errors.title}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                  <label>
                       Square Size (For Guilds, Events, Friendlists)
                        </label>
                    <input
                      onChange={(e)=>{
                        console.log(e.target.files[0]);
                        formik.setFieldValue('icon',e.target.files[0],{strict:false})
                        
                        
                        }}
                  
                      name="icon"
                      type="file"
                    
                      className="form-control"
                     
                    />
                    <span className="validation-text">
                      {formik.errors.icon ? (
                        <p style={{ color: "red" }}>{formik.errors.icon}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                  <label>
                        4x3 Image (For Profile)
                        </label>
                    <input
                      onChange={(e)=>{
                        console.log(e.target.files[0]);
                        formik.setFieldValue('game_profile',e.target.files[0],{strict:false})
                        
                        
                        }}
                  
                      name="game_profile"
                      type="file"
                    
                      className="form-control"
                     
                    />
                    <span className="validation-text">
                      {formik.errors.icon ? (
                        <p style={{ color: "red" }}>{formik.errors.icon}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {/* <!-- <button className="btn btn btn-light-dark" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Discard</button> --> */}

              <button type="submit" className="btn drk_btn">Save</button>
          
          </div>
        </form>
      </Modal>
  </Master>
  )
}
