import React from 'react'

export default function Footer() {
  return (
    <div>
       <div className="footer-wrapper mt-0">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © <span className="dynamic-year">2022</span> <a target="_blank" href="https://Gamers Playground.com/cork-admin/">Gamers Playground</a>, All rights reserved.</p>
                </div>
                <div className="footer-section f-section-2">
                    <p className="">Coded with Codobux</p>
                </div>
            </div>
    </div>
  )
}
