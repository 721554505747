import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login/Login";
import Master from "./Components/Include/Master";
import UserManagement from "./Components/UserManagement/UserManagement";
import Admin from "./Components/Admin/Admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Userverification from "./Components/Userverification/Userverification";
import Support from "./Components/Support/Support";
import AdminPrivateRoute from "./PrivateRoutes/AdminPrivateRoute";
import { Dashboard } from "./Components/Dashboard/Dashboard";
import Analytics from "./Components/Analytics/Analytics";
import WeeklyBox from "./Components/Weekly_box/WeeklyBox";
import Quest from "./Components/Quest/Quest";
import Spiner from "./Components/Quest/Spiner";
import PrizeDraw from "./Components/Prize_draw/PrizeDraw";
import Blogs from "./Components/Blogs/Blogs";
import CreateBlogs from "./Components/Blogs/createBlog/CreateBlogs";
import EditBlog from "./Components/Blogs/editBlog/EditBlog";
import ViewBlogPage from "./Components/Blogs/viewBlog/ViewBlogPage";
import Advertising from "./Components/advertising_mgt/Advertising";
import Report from "./Components/Report/Report";
import Notification from "./Components/Notifications/Notification";
import DeletedAccountList from "./Components/DeletedAccount/DeletedAccountList";
import Chat from "./Components/chat/Chat";
import UserDetail from "./Components/UserManagement/user_detail/UserDetail";
import ChangePassword from "./Components/Change_password/ChangePassword";
import Loader from "./Components/loader/Loader";
import DiscordLogin from "./Components/DiscordLogin";
import Discord from "./Components/Discord";
import Gamers from "./Components/Gamers/Gamers";
import Pool from "./Components/Pool/Pool";
import Gamelist from "./Components/Game/Gamelist";
// import ForgotPassword from "./Components/Forgot_password/ForgotPassword";
function App() {
  return (
    <Router>
      <Loader />
      <Routes>
        <Route element={<AdminPrivateRoute />}>
          <Route exact path="/admin" element={<Admin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route
            path="/usermanagement/user-detail/:id"
            element={<UserDetail />}
          />
          <Route path="/userverification" element={<Userverification />} />
          <Route path="/support" element={<Support />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/weekly-box" element={<WeeklyBox />} />
          <Route path="/quest" element={<Quest />} />
          <Route path="/spin" element={<Spiner />} />
          <Route path="/prize-draw" element={<PrizeDraw />} />
          <Route path="/advertising" element={<Advertising />} />
          <Route path="/blogs/*" element={<Blogs />} />
          <Route path="blogs/create-blog" element={<CreateBlogs />} />
          <Route path="/blogs/edit-blog/:id" element={<EditBlog />} />
          <Route path="/blogs/view-blog-page/:id" element={<ViewBlogPage />} />
          <Route path="/report" element={<Report />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/delete-acc-request" element={<DeletedAccountList />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/gamers" element={<Gamers />} />
          <Route path="/pool" element={<Pool />} />
          <Route path="/games" element={<Gamelist />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>

        <Route path="/" element={<Login />} />
        <Route path="/discordlogin" element={<DiscordLogin />} />
        <Route path="/discord" element={<Discord />} />
        {/* <Route path="/discordredirect" element={<DiscordLogin />} /> */}

        {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
