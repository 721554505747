import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
const ModalAdver = ({
  adverModalShow,
  setAdverModalShow,
  adverVal,
  stopAdvertisement,
}) => {
  console.log(adverVal, "adverVal");
  const handleClose = () => {
    setAdverModalShow(false);
  };
  return (
    <Modal show={adverModalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{adverVal?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {adverVal?.active == 0
          ? `${adverVal?.title} advertisement will be resumed and all other advertisements will be stopped`
          : `${adverVal?.title} advertisement will be stopped`}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="btn drk_btn"
          onClick={() => {
            stopAdvertisement({
              ad_id: adverVal._id,
              active: adverVal?.active == 0 ? 1 : 0,
            });
            handleClose();
          }}
        >
          OK
        </Button>
        <Button variant="btn btn-light-dark " onClick={handleClose}>
          cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdver;
