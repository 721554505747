import React from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
const BarChart = ({originData,originGraphData}) => {

const series= [{
  name:"Users",
    data: originGraphData
  }]
   
  const options = {
    
    // chart: {
    //   type: "bar",
    //   height: 500
    // },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
      },
    xaxis: {
        categories: originData
      },
  
  };

  return (
    <>
      <ReactApexChart options={options}  series={series}  type="bar" height={450} />
    </>
  );
};

export default BarChart;
