import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import Select from "react-select";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createAmins, editAdmin } from "../../Redux/Action/LoginAction";


const AddModal = ({ showModal, setShowModal, update, updateList }) => {
  console.log(updateList,"updateList");
var selectedoption=updateList?.access;

 


  const sidebarList = [
    { value: "Dashboard", label: "Dashboard" },
    { value: "Analytics", label: "Analytics" },
    { value: "WeeklyBox", label: "Weekly Box" },
    { value: "Quest", label: "Quest" },
    { value: "UserManagement", label: "User Management" },
    { value: "Support", label: "Support" },
    { value: "UserVerification", label: "User Verification" },
    { value: "Blogs", label: "Blogs" },
    { value: "PriceDraws", label: "Price Draws" },
    { value: "AdManagement", label: "Advertising Management" },
    { value: "Notification", label: "Notification" },
    // { value: "deleteAccountRqst", label: "Delete Account Request" },
    { value: "Report", label: "Report" },
    { value: "Chat", label: "Chat" },
    { value: "Admin", label: "Admin" },
    { value: "Games", label: "Games" },
    { value: "Pool", label: "Pool" },
  ];


  const [selectedList, setSelectedList] = useState([]);
  const [accessList, setAccessList] = useState();

  const dispatch = useDispatch();



  const handleSelectSidebar = (e) => {
    setSelectedList(e);
    var dataList = e.map((item) => {
      return item.value;
    });
    setAccessList(dataList);
  };





  const handleClose = () => {
    setSelectedList([])

      setShowModal(false);
      setAccessList("")
    
  
   
 
  
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name Required";
    }
    if (!values.email) {
      errors.email = "Email Required";
    }
    if ( !values.email &&update  !=true) {
      errors.password = "Password Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: update ? updateList.name : "",
      email: update ? updateList.email : "",
      password:  "",
    },
    validate,
    onSubmit: async (values) => {
      
      if (accessList?.length > 0 ||update ==true) {
        var access = {};
        accessList.forEach((tab) => (access[tab] = 1));
        let formValue = Object.assign(values, { access: access });
        if (formValue) {

          if(update ==true){
            // editAdmin
            dispatch(editAdmin(formValue));
          }else{
            
            dispatch(createAmins(formValue));
            
          }
          setShowModal(false);
        }
      } else if (accessList?.length < 0) {
        setShowModal(true);
      }
    },
  });
  const handleUpdateClick = () => {};

  //jitendra kumar
  var valueofselected=[];
  var valueofselectedvalue=[];
  useEffect(()=>{
    if(update ==true ){

  


    for(let i=0;i<sidebarList.length;i++){



      for (let x in selectedoption) {
      
        if(selectedoption[x]==1 && sidebarList[i].value==x){
        
    
          valueofselected.push(sidebarList[i]);
          valueofselectedvalue.push(sidebarList[i].value)
    
        }
      }
    }
    setSelectedList(valueofselected)
    setAccessList(valueofselectedvalue)
  }
  },"")


  console.log(formik.errors,"fsdfdsfdssfdsdsdfsdf")
  //end jitendra
  return (
    <Modal show={showModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {update == true ? "Update Admin" : "Create Admin"}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              handleClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="contact-name">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  <span className="validation-text">
                    {formik.errors.name ? (
                      <p style={{ color: "red" }}>{formik.errors.name}*</p>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="contact-email">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <span className="validation-text">
                    {formik.errors.email ? (
                      <p style={{ color: "red" }}>{formik.errors.email}*</p>
                    ) : null}
                  </span>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="contact-email">
                  <input
                    type="text"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                  />
                  <span className="validation-text">
                    {formik.errors.password ? (
                      <p style={{ color: "red" }}>{formik.errors.password}*</p>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <h6 className="mt-3 mb-3">Access</h6>

                <Select
                  isMulti
                  options={sidebarList}
                  onChange={handleSelectSidebar}
                  value={selectedList}
                />

                {/* <div className="form-check form-check-primary ">

                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="form-check-default"
                  />
                  <label className="form-check-label" for="form-check-default">
                    Support
                  </label>
                </div>
                <div className="form-check form-check-primary">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="form-check-Blog"
                  />
                  <label className="form-check-label" for="form-check-Blog">
                    Blog
                  </label>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={() => {
                handleClose();
              }}
            >
              <i className="flaticon-cancel-12"></i> Cancel
            </button>
            {
              update ?<button onClick={()=>{}} className="btn drk_btn">
              Update
            </button>:
            <button type="submit" className="btn drk_btn">
              Submit
            </button>
            }
            
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddModal;
