const continentsData = [
  {
    continent: "Asia",
    countries: [
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "Brunei",
      "Cambodia",
      "China",
      "Cyprus",
      "Georgia",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Lebanon",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "North Korea",
      "Oman",
      "Pakistan",
      "Palestine",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Thailand",
      "Timor-Leste",
      "Turkey",
      "Turkmenistan",
      "United Arab Emirates",
      "Uzbekistan",
      "Vietnam",
      "Yemen",
    ],
  },
  {
    continent: "Africa",
    countries: [
      "Nigeria",
      "South Africa",
      "Egypt",
      "Kenya",
      "Morocco",
      "Ethiopia",
      "Ghana",
      "Tanzania",
      "Algeria",
      "Uganda",
      "Sudan",
      "Mozambique",
      "Angola",
      "Zimbabwe",
      "Cameroon",
      "Zambia",
      "Senegal",
      "Chad",
      "Somalia",
      "Guinea",
      "South Sudan",
      "Rwanda",
      "Burundi",
      "Benin",
      "Burkina Faso",
      "Tunisia",
      "Malawi",
      "Mali",
      "Niger",
      "Mauritania",
      "Eritrea",
      "Namibia",
      "Gambia",
      "Botswana",
      "Gabon",
      "Guinea-Bissau",
      "Mauritius",
      "Djibouti",
      "Comoros",
      "Cabo Verde",
      "Sao Tome and Principe",
      "Seychelles",
      "Lesotho",
      "Swaziland",
    ],
  },
  {
    continent: "Europe",
    countries: [
        'Germany', 'United Kingdom', 'France', 'Italy', 'Spain', 'Poland', 'Ukraine',
        'Romania', 'Netherlands', 'Belgium', 'Czech Republic', 'Greece', 'Portugal',
        'Sweden', 'Hungary', 'Belarus', 'Austria', 'Serbia', 'Switzerland', 'Bulgaria',
        'Denmark', 'Finland', 'Slovakia', 'Norway', 'Ireland', 'Croatia', 'Bosnia and Herzegovina',
        'Lithuania', 'Latvia', 'Estonia', 'Montenegro', 'Luxembourg', 'Malta', 'Iceland',
        'Andorra', 'Monaco', 'Liechtenstein', 'San Marino', 'Vatican City'
      ]
    },
  {
    continent: "North America",
    countries: [
      'United States', 'Canada', 'Mexico', 'Guatemala', 'Cuba', 'Honduras', 'Nicaragua',
      'El Salvador', 'Costa Rica', 'Panama', 'Jamaica', 'Trinidad and Tobago', 'Dominican Republic',
      'Haiti', 'Bahamas', 'Barbados', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Grenada',
      'Antigua and Barbuda', 'Saint Kitts and Nevis', 'Belize'
    ],
  },
  {
    continent: "South America",
    countries: [
      'Brazil', 'Argentina', 'Peru', 'Colombia', 'Venezuela', 'Chile', 'Ecuador',
      'Bolivia', 'Paraguay', 'Uruguay', 'Guyana', 'Suriname'
    ]
  },
  {
    continent: "Australia",
    countries: [
        'Australia', 'New Zealand', 'Papua New Guinea', 'Fiji', 'Solomon Islands',
        'Vanuatu', 'Samoa', 'Kiribati', 'Tonga', 'Micronesia', 'Marshall Islands',
        'Palau', 'Tuvalu', 'Nauru'
      ]
  },
  {
    continent: "Antarctica",
    countries: ["Antarctica "],
  },
];

export default continentsData;
