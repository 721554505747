export const LOGIN_SUCCESSFULLY = "LOGIN_SUCCESSFULLY";
export const USER_LIST = "USER_LIST";
export const SUPPORT_LIST = "SUPPORT_LIST";
export const DASHBOARD_LIST = "DASHBOARD_LIST";
export const WEEKLY_BOX = "WEEKLY_BOX";
export const QUEST_LIST = "QUEST_LIST";
export const BLOG_LIST = "BLOG_LIST";
export const DELETE_LIST = "DELETE_LIST";
export const VIEW_BLOG = "VIEW_BLOG";
export const ADVERTISE_LIST = "ADVERTISE_LIST";
export const REPORT_LIST = "REPORT_LIST";
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const PRIZE_LIST = "PRIZE_LIST";
export const CHAT_USER_LIST = "CHAT_USER_LIST";
export const USER_CONVO_LIST = "USER_CONVO_LIST";
export const USER_CHAT = "USER_CHAT";
export const DELETED_ACC_LIST = "DELETED_ACC_LIST";
export const VIEW_USER = "VIEW_USER";
export const USERS_CONVO = "USERS_CONVO";
export const ANALYTIC_LIST = "ANALYTIC_LIST";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const INTEREST_LIST = "INTEREST_LIST";
export const AGE_AVERAGE_LIST = "AGE_AVERAGE_LIST";
export const ORIGIN_LIST = "ORIGIN_LIST";
export const INTEREST_DATA_LIST = "INTEREST_DATA_LIST";
export const ACCESS_LIST = "ACCESS_LIST";
export const ADMIN_LIST = "ADMIN_LIST";
export const DELETE_ACCOUNT_LIST="DELETE_ACCOUNT_LIST";
export const ADD_ADVER_SUCCESS="ADD_ADVER_SUCCESS";
export const START_LOADING="START_LOADING";
export const STOP_LOADING="STOP_LOADING";
export const UPDATE_ADVER_SUCCESS="UPDATE_ADVER_SUCCESS"
export const Game_LIST="Game_LIST"
export const POOL_LIST="POOL_LIST"
export const number_per_user="number_per_user"
export const averageTimeUser = "averageTimeUser"

