import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuest,
  deleteDataQuest,
  getDataQuest,
  getPool,
  updateQuest,
} from "../../Redux/Action/AdminPannel";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import Moment from "../../Utils/MomentDate";
import { useFormik } from "formik";
import Spiner from "./Spiner";
import { Modal } from "react-bootstrap";
import Moment2 from "../../Utils/Moment";
import * as Yup from "yup";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
import FormSelect from "react-bootstrap/FormSelect";
import Form from "react-bootstrap/Form";
import { Country, State, City } from "country-state-city";
const Quest = () => {
  var today = new Date().toISOString().split("T")[0];
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [img, setImg] = useState();

  const [img1, setImg1] = useState();
  const [pool, setpool] = useState("");
  const [imageName, setImageName] = useState();

  const [imageName1, setImageName1] = useState();
  const [preview1, setPreview1] = useState();

  const [preview, setPreview] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const [subscribers, setSubscribers] = useState("");
  const [subDetail, setSubDetail] = useState();
  const [country, setCountry] = useState("");
  const [sub, setSub] = useState(null);
  const [show, setShow] = useState("");
  const [upadteQuest, setUpdateQuest] = useState("");
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const [updt, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const questList = useSelector((state) => state?.AdminReducer?.quest_list);
  useEffect(() => {
    dispatch(getDataQuest());
  }, []);

  useEffect(() => {
    dispatch(getPool({ search: "" }));
  }, []);

  const List = useSelector((state) => state?.AdminReducer?.pool_list);

  const SignupSchema = Yup.object().shape({
    title: Yup.string().required("Enter title"),
    link: Yup.string().required("Provide link"),
    name_of_button: Yup.string().required("Write button name"),
    description: Yup.string().required("Write some content"),

    date: Yup.string().required("Select date"),
  });

  const formik = useFormik({
    initialValues: {
      link: "",
      name_of_button: "",
      description: "",
      date: "",
      quest: "",
      id: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("link", values.link);
      formData.append("quest", img);
      formData.append("dashboard", img1);

      formData.append("title", values.title);
      formData.append("name_of_button", values.name_of_button);
      formData.append("description", values.description);
      formData.append("date", values.date);
      // formData.append("country", country);
      if (updt) {
        formData.append("id", upadteQuest);
      }

      formData.append("pool_id", pool);
      try {
        if (updt) {
          await dispatch(updateQuest(formData));
        } else {
          await dispatch(addQuest(formData));
        }

        dispatch(getDataQuest());
        setCountry("");
        setpool("");
        // window.href="/weekly-box"
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleImageUpdate = (e) => {
    setImg(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleImageUpdate1 = (e) => {
    setImg1(e.target.files[0]);
    setPreview1(URL.createObjectURL(e.target.files[0]));
  };

  const deleteQuest = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const handlePriceDraw = (subscribers) => {
    let data = subscribers?.subscribes?.user_detail
      ?.map((d) => d.gamer_name)
      .filter((item) => item !== undefined);
    setSub(data);
    setSubDetail(subscribers);
  };

  const updateDataQuest = (value) => {
    setUpdate(true);
    setImageName(value.image);
    setImageName1(value.dashboard);
    formik.setFieldValue("link", value.link);
    formik.setFieldValue("title", value.title);
    formik.setFieldValue("name_of_button", value.name_of_button);
    formik.setFieldValue("description", value.description);
    formik.setFieldValue("date", Moment2.Date(value.date));
    // formik.setFieldValue("", Moment2.Date(value.date));

    setCountry(value?.country);
    setpool(value?.pool_id);
    // formik.setFieldValue("country",value.country)

    setUpdateQuest(value._id);
    setShow(true);
  };
  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % questList.length);
  };
  useEffect(() => {
    if (page && questList) {
      setPageCount(Math.ceil(questList.length / page));
    }
  }, [questList, page]);

  const ohHandleClose = () => {
    window.location.href = "/quest";
  };

  console.log(questList[0]?.title.toLowerCase(), "llliiisssttt");
  console.log(search, "search");

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Quest</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list">
              <div className=" d-flex justify-content-end">
                <button
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  onClick={() => {
                    setUpdate(false);
                    setShow(true);
                    formik.resetForm();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"quest"}
        />
        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll_quest">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Images</th>
                      <th>Dashboard</th>
                      {/* <th>Country</th> */}
                      <th>Title</th>
                      <th>Link</th>
                      <th>Subscribe</th>
                      <th>Actions</th>
                      <th>Live From</th>
                      <th>Price Draws</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questList
                      ?.filter((data) =>
                        data.title.toLowerCase().includes(search)
                      )
                      ?.slice(resultOffset, resultOffset + page)
                      ?.map((item, i) => {
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>
                              <div className="d-flex">
                                <div className="usr-img-frame me-2 rounded-circle">
                                  <img
                                    alt="avatar"
                                    className="img-fluid"
                                    src={
                                      IMG_URL + "questimage/" + item?.dashboard
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="usr-img-frame me-2 rounded-circle">
                                  <img
                                    alt="avatar"
                                    className="img-fluid"
                                    src={IMG_URL + "questimage/" + item?.image}
                                  />
                                </div>
                              </div>
                            </td>
                            {/* <td>{item?.country || "N/A"}</td> */}
                            <td>{item?.title}</td>
                            <td>
                              <a
                                href={
                                  item?.link.split(":")[0] == "https" ||
                                  item?.link.split(":")[0] == "http"
                                    ? item?.link
                                    : "https://" + item?.link
                                }
                              >
                                Link
                              </a>
                            </td>
                            <td>
                              <div
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                                className="subscriber"
                                onClick={() =>
                                  setSubscribers(item?.subscribes?.user_detail)
                                }
                              >
                                {item?.subscribes?.user_detail?.length}{" "}
                                Subscribers
                              </div>
                            </td>
                            <td className="custom_pd">
                              <a
                                onClick={() => updateDataQuest(item)}
                                className="delete_ml"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a>

                              <a onClick={() => deleteQuest(item?._id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                            <td>{Moment.Date(item?.date)}</td>
                            <td>
                              {item?.subscribes?.user_detail.length > 0 ? (
                                <button
                                  className="btn drk_btn quest_size"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal2"
                                  onClick={() => handlePriceDraw(item)}
                                >
                                  Price Draws
                                </button>
                              ) : (
                                <button
                                  className="btn drk_btn quest_size"
                                  data-bs-toggle="modal"
                                  // data-bs-target="#exampleModal2"
                                >
                                  No subscribers
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>

      {/* <!-- Modal 1  Start--> */}
      <Modal show={show} onHide={!show} className="add-quest-modal">
        <form className="modal-content" onSubmit={formik.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {updt ? "Edit Quest" : "Add New Quest"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(false);
                setPreview(null);
                setpool("");
                formik.resetForm();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="modal_inner_content">
              <form action="" className="height-overflow">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    {/* <Form.Select
                  name="country"
                  value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                   <option>Select Country</option>
                  {Country.getAllCountries()?.map((country)=>{
                    return (
                      <option value={country?.name}>{country?.name}</option>
                    )
                  })}
                   
                   
                  </Form.Select> */}
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="contact-name">
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        name="title"
                        type="text"
                        id=""
                        className="form-control"
                        placeholder="Title"
                      />
                      <span className="validation-text">
                        {!formik.values.title && formik.errors.title ? (
                          <p style={{ color: "red" }}>{formik.errors.title}*</p>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.link}
                        name="link"
                        type="text"
                        id=""
                        className="form-control"
                        placeholder="Link"
                      />

                      <span className="validation-text">
                        {!formik.values.link && formik.errors.link ? (
                          <p style={{ color: "red" }}>{formik.errors.link}*</p>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.name_of_button}
                        name="name_of_button"
                        type="text"
                        id=""
                        className="form-control"
                        placeholder="Name of Button"
                      />

                      <span className="validation-text">
                        {!formik.values.name_of_button &&
                        formik.errors.name_of_button ? (
                          <p style={{ color: "red" }}>
                            {formik.errors.name_of_button}*
                          </p>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="contact-name">
                      <textarea
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        name="description"
                        id=""
                        cols="30"
                        rows="4"
                        className="form-control"
                        placeholder="Description"
                      ></textarea>
                      <span className="validation-text">
                        {!formik.values.description &&
                        formik.errors.description ? (
                          <p style={{ color: "red" }}>
                            {formik.errors.description}*
                          </p>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    {updt ? (
                      <div className="contact-email small-popup-img-upload">
                        <input
                          onChange={handleImageUpdate}
                          name="quest"
                          type="file"
                          className="form-control"
                          placeholder="Upload Photo"
                        />
                        <div className="view-small-upload">
                          {preview ? (
                            <img
                              className="view-small-upload"
                              alt="preview"
                              src={preview}
                            />
                          ) : (
                            <img
                              className="view-small-upload"
                              alt="img"
                              src={IMG_URL + "questimage/" + imageName}
                            />
                          )}
                        </div>
                        <span className="validation-text"></span>
                      </div>
                    ) : (
                      <div className="contact-email">
                        <label>
                          Dashboard image(image size should be in square size)
                        </label>
                        <input
                          onChange={(e) => setImg(e.target.files[0])}
                          name="weeklyboximage"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                        />

                        <span className="validation-text"></span>
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    {updt ? (
                      <div className="contact-email small-popup-img-upload">
                        <input
                          onChange={handleImageUpdate1}
                          name="quest"
                          type="file"
                          className="form-control"
                          placeholder="Upload Photo"
                        />
                        <div className="view-small-upload">
                          {preview1 ? (
                            <img
                              className="view-small-upload"
                              alt="preview"
                              src={preview1}
                            />
                          ) : (
                            <img
                              className="view-small-upload"
                              alt="img"
                              src={IMG_URL + "questimage/" + imageName1}
                            />
                          )}
                        </div>
                        <span className="validation-text"></span>
                      </div>
                    ) : (
                      <div className="contact-email">
                        <label>
                          Detail image(image size should be in square size)
                        </label>
                        <input
                          onChange={(e) => setImg1(e.target.files[0])}
                          name="weeklyboximage"
                          type="file"
                          id=""
                          className="form-control"
                          placeholder="Upload Photo"
                        />

                        <span className="validation-text"></span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="contact-email">
                      <label>Live by date</label>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.date}
                        name="date"
                        type="date"
                        id=""
                        className="form-control"
                        placeholder="Live by Date"
                        min={today}
                      />
                      <span className="validation-text">
                        {!formik.values.date && formik.errors.date ? (
                          <p style={{ color: "red" }}>{formik.errors.date}*</p>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="contact-email mt-3">
                      <label>
                        <strong>Select Pool</strong>
                      </label>
                      <Form.Select
                        name="pool_id"
                        value={pool}
                        onChange={(e) => {
                          setpool(e.target.value);
                        }}
                      >
                        <option>
                          <strong>Select Pool</strong>
                        </option>
                        {List[0]?.map((pool) => {
                          return (
                            <option value={pool?._id}>{pool?.title}</option>
                          );
                        })}

                        {/* <option>Pool 2</option>
                  <option>Pool 3</option>
                  <option>Pool 4</option>
                  <option>Pool 5</option> */}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={() => {
                setPreview(null);
                setShow(false);
                setpool("");
                formik.resetForm();
              }}
            >
              <i className="flaticon-cancel-12"></i> Discard
            </button>
            {formik.values.title &&
            formik.values.link &&
            formik.values.name_of_button &&
            formik.values.description &&
            formik.values.date ? (
              <button
                type="submit"
                className="btn drk_btn"
                data-bs-dismiss="modal"
                onClick={() => setShow(false)}
              >
                Save
              </button>
            ) : (
              <button
                type="button"
                className="btn drk_btn"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </Modal>

      {/* <!-- Modal 1  End--> */}

      {/* <!-- Modal 2  Start--> */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Subscribers
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="searchable-container searchable-items">
                      {subscribers?.length > 0 ? (
                        subscribers
                          ?.slice(resultOffset, resultOffset + page)
                          ?.map((item, i) => {
                            return (
                              <div className="user-profile d-flex subscriber-img mt-4">
                                <img
                                  src={
                                    IMG_URL +
                                    "user_profile_image/" +
                                    item.main_image
                                  }
                                  alt="avatar"
                                  className="rounded"
                                />
                                <div className="user-meta-info ms-2">
                                  <p className="user-name mb-0">
                                    {item.gamer_name}
                                  </p>
                                  <p className="user-email">{item?.email}</p>
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <div>No subscribers</div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn btn-light-dark"
                data-bs-dismiss="modal"
              >
                <i className="flaticon-cancel-12"></i> Discard
              </button>
              <button type="button" className="btn drk_btn">
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>

      {/* <!-- Modal 2  End--> */}

      {/* <!-- Modal 3  Start--> */}

      <div
        className="modal fade spinner_modal"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Price Draws
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => ohHandleClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            <div className="modal-body modal_spinner">
              <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    <div id="chart">
                      {sub && (
                        <Spiner
                          subDetail={subDetail}
                          subscribers={sub}
                          qlist={questList}
                        />
                      )}
                    </div>
                    <div id="question" className="spinner_diff">
                      <h1></h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- <div className="modal-footer">
                            <button className="btn btn btn-light-dark" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Discard</button>
                            <button type="button" className="btn drk_btn">Save</button>
                        </div> --> */}
          </div>
        </div>
      </div>

      {/* <!-- Modal 3  End--> */}
    </Master>
  );
};

export default Quest;
